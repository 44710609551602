<!--
 * @name:
 * @test: test font
 * @msg:
 * @param:
 * @return:
-->
<template>
  <div class="content-left">
    <div class="login-info">
      <img
        src="@/assets/imgs/NewLogo/left_img_ads_logo.svg"
        alt=""
        class="logo1"
      />
      <div class="welcome-title">
        <div class="ft-56 fw-800 color2">Welcome to</div>
        <div class="ft-56 fw-800 color1 mb-24">USAdrop Payment Solution</div>
        <div class="color2 fw-500 ft-32">USAdrop Payment Solution Service</div>
      </div>
      <img src="@/assets/imgs/loginImgs/payWays.png" alt="" class="logo2" />
      <div class="bottomContent">
        <div class="ft-24 mb-24">4 main reasons why choose usadrop payment solution</div>
        <div class="items">
          <div class="content-item">
            <span class="circle">1</span>
            <div class="ft-20 middle">Seamless Dropship Payments</div>
            <div class="ft-12 tipinfo">Swift transactions, no more waiting for your funds.</div>
          </div>
          <div class="content-item">
            <span class="circle">2</span>
            <div class="ft-20 middle">Global Dropshipping Access</div>
            <div class="ft-12 tipinfo">Eliminate international payment hassles effortlessly.</div>
          </div>
          <div class="content-item">
            <span class="circle">3</span>
            <div class="ft-20 middle">Stability & Trust Gateway</div>
            <div class="ft-12 tipinfo">
              Your reliable partner in continuous dropshipping success.
            </div>
          </div>
          <div class="content-item">
            <span class="circle">4</span>
            <div class="ft-20 middle">Diverse Payment Channels</div>
            <div class="ft-12 tipinfo">Support for PayPal, Credit Cards, Klarna, and more.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFrom } from '@/utils/tools'
export default {
  data: () => {
    return {
      logoImg: {
        'app.usadrop.com': require('@/assets/imgs/NewLogo/LogoOne.svg'),
        'vn.usadrop.com': require('@/assets/imgs/NewLogo/VNLogoSlice.png')
      },
      formLink: getFrom()
    }
  }
}
</script>
<style scoped lang="scss">
$color1: #bc0c34;
$color2: #15104b;
.login-info {
  // width: 60%;
  margin-right: 80px;
  text-align: left;
  .logo1 {
    width: 225px;
    height: 189px;
    margin-bottom: 40px;
  }
  .logo2 {
    width: 428px;
    height: 42px;
    margin: 32px 0 32px 0;
  }

  .bottomContent {
    color: $color2;
    width: 876px;
    height: 240px;
    border-radius: 16px;
    background: #e0e3f2;
    text-align: center;
    padding: 24px 24px;
    .items {
      display: flex;
      justify-content: space-between;
      .content-item {
        width: 24.5%;
        display: flex;
        align-items: center;
        flex-direction: column;
        .circle {
          background: $color2;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-bottom: 8px;
          color: #ffffff;
          font-weight: 600;
          font-size: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .tipinfo {
          width: 83%;
        }
        .middle {
          margin-bottom: 8px;
        }
      }
    }
  }
  .welcome-title {
  }
}
.login-content {
  width: 503px;
  height: 708px;
  margin-top: -6%;
  background: #fff;
  border-radius: 30px;
  padding: 20px 40px;
  position: relative;
  .logo2 {
    width: 90px;
    height: 64px;
    position: absolute;
    left: 40px;
    margin-bottom: 30px;
  }
  .login-head {
    font-size: 24px;
    color: #000000;
    font-weight: 500;
    margin-top: 104px;
    margin-bottom: 40px;
    text-align: center;
  }
  .forget {
    width: 100%;
    text-align: right;
    position: absolute;
    top: 44px;
  }
  .option {
    width: 420px;
    height: 100px;
    margin: 0 auto 10px;
    padding-left: 3px;
    ::v-deep .el-input__inner {
      // box-shadow: none;
      border-radius: 50px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      width: 100%;
      height: 42px;
      border: 1px solid #cccccc;
      // .el-input__inner {
      //   border-radius: 50px;
      //   font-family: 'Regular';
      //   -webkit-font-smoothing: antialiased;
      //   width: 100%;
      //   height: 48px;
      //   border: 1px solid #cccccc;
      // }
    }
  }
  .login-bottom {
    margin-top: 80px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .el-button {
      width: 226px;
      height: 44px;
      background: $color2;
      font-size: 16px;
      margin-bottom: 8px;
      color: #ffffff;
      border-radius: 30px;
    }
    .small {
      font-size: 14px;
      color: #999999;
    }
  }

  .demo-ruleForm {
    ::v-deep .el-form-item {
      margin-bottom: 0 !important;
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}
.btnContent {
  display: flex;
  width: 620px;
  flex-wrap: wrap;
  justify-content: space-between;
  .btnInfo {
    background: #e0e3f2;
    box-shadow: 2px 3px 4px 0px #231d6540;
    width: 260px;
    border-radius: 16px;
    margin-bottom: 40px;
    font-size: 16px;
    color: $color2;
    display: flex;
    align-items: center;
    padding: 8px;
    img {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
    .tip-text {
      padding-left: 8px;
      color: $color2;
      font-weight: 600;
      border-left: 1.5px solid $color2;
    }
  }
}
</style>
