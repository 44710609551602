<template>
  <div class="store">
    <div class="particulars" :class="[theme === 'common' ? '' : 'dark']">
      <div class="btn_box">
        <el-button
          type="success"
          v-if="paymentList.IsAdmin == true"
          class="orders"
          @click="Added"
          circle
          >{{ $t('store.orders') }}
        </el-button>
        <a href="https://shopify.pxf.io/aONbVN" target="_blank">
          <el-tooltip
            class="item"
            effect="dark"
            content="If you don't have a Shopify store, click this button to create one quickly."
            placement="top-start">
            <p class="comBtn linkStore btn-bg2">
              Bulid your shopify store
            </p>
          </el-tooltip>
        </a>
        <el-tooltip
          class="item"
          effect="dark"
          v-if="paymentList.IsAdmin == true"
          content="Non-Shopify stores can process orders here or contact sales agents."
          placement="top-start">
          <p @click="AddOfflineStore" class="comBtn AddOfflineStore btn-bg5">
            {{ $t('store.AddOtherStore') }}
          </p>
        </el-tooltip>
        <el-button
        :loading="addTTLoading"
        type="success"
        v-if="paymentList.IsAdmin == true && showAddTikTokBtn == true"
        class="add-tt-btn"
        @click="ttAdded"
        circle
        >{{ $t('store.addTikTokStore') }}
      </el-button>
        <el-button @click="InventorylistBtn" class="Inventorylist" v-if="InventoryListBtn">{{
          $t('Inventorylist.Inventorylist')
        }}</el-button>
      </div>
      <div class="activeName">
        <el-tabs v-model="activeName">
          <el-tab-pane :label="$t('store.settings')" class="first" name="first">
            <template v-if="StoreList && StoreList.length > 0">
              <el-table :data="StoreList" style="width: 100%">
                <el-table-column prop="StoreName" align="center" :label="$t('store.name')">
                </el-table-column>
                <el-table-column align="center" :label="$t('store.status')">
                  <div slot-scope="scope" class="content">
                    <span v-if="scope.row.StoreType !== 'Offline'">
                      <span
                        v-if="scope.row.Status === 1"
                        style="color: #6788ff"
                        class="bakingpackaging"
                        >{{ $t('store.Authorized') }}</span
                      >
                      <span
                        v-if="scope.row.Status === -1"
                        style="color: #e6a23c"
                        class="bakingpackaging"
                        >{{ $t('store.Unauthorized') }}</span
                      >
                      <span
                        v-if="scope.row.Status === -2"
                        style="color: #ff6767"
                        class="bakingpackaging"
                        >{{ $t('store.Unauthorized') }}</span
                      >
                      <span v-if="scope.row.Status === -3" class="bakingpackaging">{{
                        $t('store.Unauthorized')
                      }}</span>
                    </span>
                    <span v-else>
                      <span style="color: #6788ff">Non-Shopify</span>
                    </span>
                  </div>
                </el-table-column>
                <el-table-column
                  prop="CreateDate"
                  align="center"
                  :label="$t('store.authorization')"
                >
                </el-table-column>
                <el-table-column align="center" :label="$t('store.actions')">
                  <div slot-scope="scope">
                    <template v-if="scope.row.StoreType !== 'Offline'">
                      <template v-if="scope.row.StoreType === 'Tiktok'">
                        <div class="status comBtn col-col5 border-col5" @click="ttAdded(true)">
                          {{ $t('store.reauthorize') }}
                        </div>
                      </template>
                      <template v-else>
                        <div
                          v-if="paymentList?.AddStoreForHand !== true"
                          class="status comBtn col-col5 border-col5"
                          @click="actions(scope.row.ApiColumn1)"
                        >
                          {{ $t('store.reauthorize') }}
                        </div>
                      </template>
                    </template>
                  </div>
                </el-table-column>
              </el-table>
            </template>
            <el-empty description="No data" v-else></el-empty>
          </el-tab-pane>
        </el-tabs>
       <!--  -->
      </div>
      <!-- 弹出框 -->
      <!--  <el-dialog :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <div class="Add-Store">{{ $t("store.orders") }}</div>
          <el-form-item :label-width="formLabelWidth" class="Store">
            <span class="Name">{{ $t("store.store") }}</span>
            <el-input
              v-model.trim="form.name"
              :placeholder="$t('store.please')"
              autocomplete="off"
            ></el-input>
            <span class="myshopify">{{ $t("store.myshopify") }}</span>
          </el-form-item>
        </el-form>
        <div class="NoShopifyStoreTitle">
          <p>{{ $t("store.NotShopify") }}</p>
        </div>
        <div slot="footer" class="dialog-footer">
          <div class="footerbox">
            <button class="Cancel btn_com comBtn col-col0 border-col0" @click="Cancel">{{ $t("store.cancel") }}</button>
            <button class="Save btn_com comBtn btn-bg0 border-col0" type="primary" @click="confirmStore">{{
                $t("store.save")
              }}
            </button>
          </div>
        </div>
      </el-dialog> -->
      <!-- 添加线下店铺 -->
      <el-dialog :visible.sync="dialogFormOffline">
        <el-form :model="form">
          <div class="Add-Store">{{ $t('store.AddOther') }}</div>
          <el-form-item :label-width="formLabelWidth" class="Store">
            <span class="Name">{{ $t('store.store') }}</span>
            <el-input
              v-model.trim="form.name"
              :placeholder="$t('store.pleaseLink')"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="NoShopifyStoreTitle">
          <p>{{ $t('store.AddOtherStoreLife') }}</p>
        </div>
        <div class="dialog-footer">
          <div class="footerbox">
            <button class="Cancel btn_com comBtn col-col0 border-col0" @click="Cancel">
              {{ $t('store.cancel') }}
            </button>
            <button
              class="Save btn_com comBtn btn-bg0 border-col0"
              type="primary"
              @click="confirmOfflineStore(form.name)"
            >
              {{ $t('store.save') }}
            </button>
          </div>
        </div>
      </el-dialog>

      <!-- 首次绑定店铺送90天会员 -->
      <div class="paybox" v-if="sotrePop">
        <div class="storeDialog">
          <div class="word">
            Congratulations on getting <br> 3 months Plus membership！
          </div>
          <div class="close_btn" @click="CloseBtn">Close</div>
        </div>
      </div>
    </div>
    <add-shopify-store-dialog v-model="showAddShopifyStore" @onAddSuccess="getData()" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { searchUrlParams } from '@/utils/tools'
import { queryTTlink } from '@/api/tiktok'
import AddShopifyStoreDialog from '@/views/setting/Components/AddShopifyStoreDialog.vue'

export default {
  data: () => {
    return {
      addTTLoading: false,
      InventoryListBtn: false,
      dispatch: null,
      page: 'Store',
      activeName: 'first',
      banner: '',
      itemname: '',
      StoreList: [], // 会员店铺列表
      value: '',
      dialogFormVisible: false, //弹框控制
      showAddShopifyStore: false, //添加shopify店铺弹框控制
      currentPage: 1,
      form: {
        //店铺名字
        name: ''
      },
      formLabelWidth: '120px',
      dialogFormOffline: false,
      DisplayShowWords: false,
      paymentList: {},
      showAddTikTokBtn: false,
      sotrePop: false
    }
  },
  components: { AddShopifyStoreDialog },
  computed: {
    ...mapState(['theme', 'userInfo'])
  },
  created() {
    this.dispatch = this.$store.dispatch
    const params = this.$route.params || {}
    const search = searchUrlParams(decodeURIComponent(location.search)) || {}
    const { type } = params
    const authInfo = search
    if (type !== 'addstore' && type !== 'reauthorize') return
    authInfo && this.getAuthInfo(params, authInfo)
  },
  mounted() {
    // 生产环境测试用,如果是特定账号开启
    const currentEmail = localStorage.getItem('email')
    if (currentEmail === '471115772@qq.com') {
      this.showAddTikTokBtn = true
    } else {
      this.showAddTikTokBtn = false
    }

    this.banner = this.$t('store.bannername')
    this.itemname = this.$t('store.itemname')
    this.getData()
    this.menterData()
    this.InventoryGetList()
  },
  methods: {
    menterData() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          this.paymentList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取备货库存列表
    InventoryGetList() {
      let that = this
      that
        .dispatch('store/InventoryGetListSt', {
          CurrentPageIndex: 1,
          PageSize: 10
        })
        .then((res) => {
          if (res?.Items?.length > 0) {
            this.InventoryListBtn = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    InventorylistBtn() {
      window.location.href = `${window.location.origin}/Inventorylist`
    },
    pageChange(e) {
      console.log(e)
    },
    ttAdded(fullScreenLoading = false) {
      let that = this
      let loading = null

      if (!fullScreenLoading) {
        that.addTTLoading = true
      } else {
        loading = that.$loading({
          lock: true,
          spinner: 'el-icon-loading',
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
      }

      queryTTlink()
        .then((res) => {
          // 检查res不为null且res.Success为真
          if (res && res.Success) {
            // 使用可选链操作符(?.)确保不会因为res.Result为null/undefined而抛出错误
            const url = res.Result?.ShopOAuthUrl
            // 若url存在且为字符串类型，则进行跳转
            if (typeof url === 'string' && url.trim() !== '') {
              window.location.href = url
            } else {
              // 若url不存在或不合法，则显示错误信息
              this.$message.error(this.$t('tips.storeLinkError'))
            }
          } else {
            // res为null或res.Success不为真时，显示错误信息
            this.$message.error(this.$t('tips.storeLinkError'))
          }
          if (!fullScreenLoading) {
            that.addTTLoading = fasle
          } else {
            if (loading) {
              setTimeout(() => {
                loading.close()
              }, 1000)
            }
          }
        })
        .catch((error) => {
          // 捕获queryTTlink可能抛出的异常，并显示错误信息
          console.error(error) // 推荐在控制台打印实际错误信息以便调试
          this.$message.error(this.$t('tips.storeLinkError'))
          if (!fullScreenLoading) {
            that.addTTLoading = fasle
          } else {
            if (loading) {
              setTimeout(() => {
                loading.close()
              }, 250)
            }
          }
        })
    },
    Added() {
      if (this.paymentList?.AddStoreForHand === true) {
        this.showAddShopifyStore = true
      } else {
        window.location.href = 'https://apps.shopify.com/usadrop'
      }
    },
    // 获取会员店铺列表
    getData() {
      let that = this
      that
        .dispatch('store/GetListSt')
        .then((res) => {
          that.StoreList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
      /* this.ExistStore(); */
    },
    // 获取绑定店铺授权链接
    actions(name, isAdd) {
      let that = this
      that
        .dispatch('store/GetOauthRedirectUrlSt', {
          storeName: name
        })
        .then((res) => {
          if (res.Success == true) {
            let oauthUrl = res.Result
            let locationUrl = window.location.origin
            let destinationUrl = ''
            if (isAdd) {
              destinationUrl = locationUrl + '/Store/addstore'
              // destinationUrl = locationUrl + "/loading";
            } else {
              destinationUrl = locationUrl + '/Store/reauthorize'
            }
            destinationUrl = escape(destinationUrl)
            oauthUrl = escape(oauthUrl)
            // console.log(oauthUrl);
            let shopifyUrl = `https://webapi.usadrop.com/ShopifyOAuth/OAuth.html?shop=${name}&destinationUrl=${destinationUrl}&oauthUrl=${oauthUrl}`
            // console.log(shopifyUrl);
            window.location.href = shopifyUrl
          } else {
            that.$message.error(that.$t('tips.storeLinkError'))
          }
          /* that.ExistStore(); */
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 弹出框 取消按钮
    Cancel() {
      this.form.name = ''
      this.dialogFormVisible = false
      this.dialogFormOffline = false
    },
    // 点击添加店铺进入授权
    confirmStore() {
      let that = this
      if (that.form.name == '') {
        that.$message.error(that.$t('tips.storeNameEmpty'))
        return
      }
      that.ExistStore()
      /* that.actions(that.form.name, "add"); */
    },
    getAuthInfo(params, authInfo) {
      // 授权信息接口解密
      let that = this
      that
        .dispatch('store/ReceiveShopifyTokenSt', {
          code: authInfo.code,
          hmac: authInfo.hmac,
          shop: authInfo.shop,
          timestamp: authInfo.timestamp
        })
        .then((res) => {
          authInfo.shop = authInfo.shop.replace('.myshopify.com', '')
          if (res.Success == true) {
            if (params.type == 'addstore') {
              this.AddStore(authInfo.shop, res.ResData, 'Shopify')
            } else if (params.type == 'reauthorize') {
              that.refreshStore(authInfo.shop, res.ResData)
            }
          } else {
            that.$message.error(that.$t('tips.storeAuthFail'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 更新店铺
    refreshStore(name, token) {
      const that = this
      that
        .dispatch('store/RefreshStoreSt', {
          StoreName: name,
          Token: token
        })
        .then((res) => {
          if (res?.Success) {
            that.$message.success(that.$t('tips.storeReauthorizeStore'))
            that.getData() //获取更新后店铺列表
            window.location.href = '/Store'
          } else {
            that.$message.error(that.$t('tips.storeAddFail'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    AddOfflineStore() {
      this.dialogFormOffline = true
    },
    // 添加店铺
    confirmOfflineStore(name) {
      //console.log(name)
      let that = this
      that
        .dispatch('store/AddStoreSt', {
          StoreName: name,
          StoreType: 'Offline'
        })
        .then((res) => {
          if (res.Success) {
            that.getData()
            this.dialogFormOffline = false
            that.$message.success(that.$t('tips.storeAddStore'))
          } else {
            that.$message.error(that.$t('tips.storeAddFail'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    CloseBtn(){
      this.sotrePop = false
    },
    // 添加店铺
    AddStore(name, token, storeType) {
      let that = this
      that
        .dispatch('store/AddStoreSt', {
          StoreName: name,
          MemberCode: '',
          StoreType: storeType,
          Key: '',
          Token: token
        })
        .then((res) => {
          if (res?.Success) {
            this.sotrePop = !!res.ResData;
            that.getData()
            setTimeout(() => {
              window.location.href = '/NewStore'
            }, 666)
          } else {
            that.$message.error(that.$t('tips.storeAddFail'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
      /*
          点击Connect或者ReConnect时，统一经由：
          https://webapi.hzpdex.com/ShopifyOAuth/OAuth.html?shop={shop}&destinationUrl={destinationUrl}&oauthUrl={oauthUrl}
          接收参数：
          1)、shop
              店铺的二级域名（填写的字符串）
          2)、oauthUrl
              去shopify授权的网址（来自于接口：/api/Store/GetOauthRedirectUrl）
          3)、destinationUrl
              Shopify同意授权后，回到的前端页面
          补充：授权最终跳转回destinationUrl后，URL上会自动带上code,hmac,shop,state几个参数。
          1)、在该页面上调用/api/Store/ReceiveShopifyToken
          2)、再调用 /api/Store/AddStore （触发于添加按钮）  或者  /api/Store/RefreshStore （触发于Reconnect）
          */
    },
    // 判断店铺是否存在
    ExistStore() {
      this.dispatch('guide/ExistStoreGu', {
        StoreKey: this.form.name
      })
        .then((res) => {
          if (res?.Success) {
            this.$message.error(this.$t('store.TheStoreBefore'))
            //this.getData(); //获取更新后店铺列表
          } else {
            this.actions(this.form.name, 'add')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  }
}
</script>

<style scoped lang="scss">
$col-col5: #3f6aff;
.store {
  width: 1400px;
  font-family: 'Regular';
}

.particulars {
  width: 100%;
  background: #eff1f3;
  min-width: 1320px;
  border-radius: 10px;
  font-family: 'Regular';
  position: relative;
}
.paybox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  .storeDialog {
    background: url('../../assets/imgs/guide/UD-SOTRE.svg')
    no-repeat;
    background-size: 600px 400px;
    width: 600px; /* 与图片宽度匹配 */
    height: 400px; /* 与图片高度匹配 */
    z-index: 100;
    position: absolute;
    top: 200px;
    left: 700px;
  }
  .word {
    font-size: 22px;
    margin-top: 100px;
    margin-left: 150px;
    line-height: 60px;
  }
  .close_btn {
    width: 150px;
    height: 40px;
    border-radius: 50px;
    text-align: center;
    color: #fff;
    background: #15104b;
    margin-left: 230px;
    font-size: 18px;
    line-height: 40px;
    margin-top: 10px;
  }
}
.btn_box {
  width: 100%;
  background: #fff;
  min-width: 1320px;
  border-radius: 10px;
  line-height: 70px;
  .add-tt-btn {
    display: inline-block;
    width: 180px;
    height: 40px;
    background-color: #000000;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    border: 1px solid #000000;
    margin-left: 15px;
    &:hover {
      background: #4d4d4d;
      color: #fff;
    }
  }
  .orders {
    display: inline-block;
    width: 180px;
    height: 40px;
    background-color: #93df89;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    margin-left: 50px;
    border: 1px solid #93df89;
    &:hover {
      background: #bff4b8;
      color: #fff;
    }
  }
  .Inventorylist {
    width: 180px;
    height: 40px;
    background: #3f6aff;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    border: 1px solid #3f6aff;
    margin-left: 15px;
    &:hover {
      background: #6c8dff;
      color: #fff;
    }
  }
  .AddOfflineStore {
    width: 180px;
    margin-left: 15px;
    &:hover {
      background: #6c8dff !important;
      color: #fff;
    }
  }
  .linkStore {
    width: 230px;
    margin-left: 15px;
    &:hover {
      background: #bff4b8;
      color: #fff;
    }
  }
}

.activeName {
  border-radius: 10px;
  line-height: 20px;
  position: relative;
  padding-bottom: 32px;
  margin-top: 20px;
}

/* 标签页内容区的总长度 */
::v-deep .activeName .el-tabs__content {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.NoShopifyStoreTitle {
  text-align: center;
  p {
    font-style: italic;
    color: rgb(76, 139, 245);
  }
}

::v-deep .el-tabs__nav-scroll {
  height: 80px;
  padding: 20px 0;
}

::v-deep .el-tabs__nav {
  margin-left: 32px;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: $col-col5;
}

::v-deep .el-table th,
.el-table tr {
  height: 71px;
}

::v-deep .el-table__row {
  height: 86px !important;
}

::v-deep .el-table__expanded-cell[class*='cell'] {
  width: 1320px !important;
  padding-left: 294px !important;
  padding-top: 24px !important;
  padding-bottom: 12px !important;
}

::v-deep .el-tabs__header {
  margin: 0 !important;
  background-color: #fff;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.status {
  font-size: 14px;
  height: 40px;
}

::v-deep .status.el-button--primary.el-button:hover {
  background: #e9efff;
  color: #6788ff;
}

/* 标签页下划线 */
::v-deep .el-tabs__active-bar {
  height: 3px !important;
  background: #6788ff;
}
/* 新增弹框 */
::v-deep .el-dialog__wrapper {
  height: 966px;
}

::v-deep .el-dialog {
  margin-top: 25vh !important;
  width: 810px !important;
  min-height: 282px !important;
  background: #ffffff !important;
  box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.14) !important;
  border-radius: 21px !important;
  overflow: hidden;
}

.Add-Store {
  background-color: #ffffff;
  width: 810px;
  height: 55px;
  line-height: 55px;
  color: #333333;
  padding-left: 42px;
  font-size: 16px;
  border-bottom: 1px solid #e4e6ef;
}

::v-deep .el-dialog__header {
  padding: 0 !important;
}

::v-deep .el-dialog__body {
  padding: 0 !important;
}

::v-deep .el-dialog__headerbtn {
  position: absolute !important;
  top: 20px !important;
  right: 32px !important;
  padding: 0 !important;
  background: 0 0 !important;
  border: none !important;
  outline: 0 !important;
  cursor: pointer !important;
  font-size: 16px !important;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #fff !important;
}

.Store {
  margin-top: 32px;
}

.Store .Name {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  line-height: 19px;
  opacity: 0.8;
}

::v-deep .Store .el-form-item__content {
  margin-left: 118px !important;
  height: 48px;
}

::v-deep .Store .el-input {
  display: inline-block;
  font-size: 14px;
  width: 320px;
  height: 48px;
  background: #ffffff;
  margin-left: 32px;
}

::v-deep .Store .el-input__inner {
  width: 320px;
  height: 40px;
  border-radius: 24px;
  padding-left: 52px;
  font-size: 14px;
  overflow: hidden;
}

::v-deep .el-input__inner:focus {
  border-color: #c0c4cc;
}

.btn_com {
  width: 200px;
  height: 45px;
  border-width: 2px;
  border-style: solid;
  font-weight: 600;
}

.Cancel {
  position: absolute;
  top: 202px;
  left: 176px;
}

.Save {
  position: absolute;
  top: 202px;
  left: 474px;
  border-width: 2px;
  border-style: solid;
}

::v-deep .el-tabs__nav-wrap::after {
  background: #eff1f3 !important;
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}
</style>
