var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "content" }, [
      _vm.type === 1
        ? _c(
            "div",
            { staticClass: "main" },
            [
              _c("LoginContentLeft"),
              _c(
                "div",
                { staticClass: "RegisterRight" },
                [_vm._m(0), _c("email-register")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "register" }, [
      _c("div", { staticClass: "LogoBig" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/imgs/NewLogo/ny_logo.svg"),
            alt: "",
            loading: "lazy",
          },
        }),
      ]),
      _c("div", { staticClass: "account" }, [
        _c("p", [_vm._v("Welcome to USAdrop")]),
        _c("p", [_vm._v("Payment Solution")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }