<template>
  <div class="ad-login">
    <div class="login-info">
      <img
        src="@/assets/imgs/NewLogo/left_img_ads_logo.svg"
        alt=""
        class="logo1" />
      <div class="welcome-title">
        <div class="ft-56 fw-800 color2">Welcome to</div>
        <div class="ft-56 fw-800 color1 mb-24">USAdrop Payment Solution</div>
        <div class="color2 fw-500 ft-32">USAdrop Payment Solution Service</div>
      </div>
      <img
        src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580863920_payWays.png"
        alt=""
        class="logo2" />
      <div class="bottomContent">
        <div class="ft-24 mb-24">4 main reasons why choose usadrop payment solution</div>
        <div class="items">
          <div class="content-item">
            <span class="circle">1</span>
            <div class="ft-20 middle">Seamless Dropship Payments</div>
            <div class="ft-12 tipinfo">Swift transactions, no more waiting for your funds.</div>
          </div>
          <div class="content-item">
            <span class="circle">2</span>
            <div class="ft-20 middle">Global Dropshipping Access</div>
            <div class="ft-12 tipinfo">Eliminate international payment hassles effortlessly.</div>
          </div>
          <div class="content-item">
            <span class="circle">3</span>
            <div class="ft-20 middle">Stability & Trust Gateway</div>
            <div class="ft-12 tipinfo">
              Your reliable partner in continuous dropshipping success.
            </div>
          </div>
          <div class="content-item">
            <span class="circle">4</span>
            <div class="ft-20 middle">Diverse Payment Channels</div>
            <div class="ft-12 tipinfo">Support for PayPal, Credit Cards, Klarna, and more.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="login-content">
      <img
        src="@/assets/imgs/NewLogo/right_logo_3.svg"
        alt=""
        class="logo3" />
      <p class="login-head">Welcome to USAdrop Payment</p>
      <el-form label-position="top" :rules="rules" :model="formData" ref="formRef">
        <el-form-item label="E-mail" class="option" prop="username">
          <el-input v-model="formData.username" />
        </el-form-item>
        <el-form-item label="Password" class="option" prop="password">
          <el-input v-model="formData.password" :show-password="true" />
        </el-form-item>
        <div class="login-bottom">
          <div class="btns">
            <el-button @click="signIn('formRef')">Sign In</el-button>
            <div class="small">
              Don't have an account?
              <span style="cursor: pointer; color: #3f6aff" @click="goRegister">Sign Up Here</span>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { searchUrlParams } from '@/utils/tools'

export default {
  data() {
    return {
      formData: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: 'Field  cannot be empty ', trigger: 'blur' },
          {
            type: 'email',
            message: 'Please enter the correct Email address',
            trigger: ['blur', 'change']
          }
        ],
        password: [{ required: true, message: 'Field  cannot be empty', trigger: 'blur' }]
      }
    }
  },
  created() {
    const { dispatch } = this.$store // 获取授权时获取的state code
    const search = decodeURIComponent(location.search)
    const { CofunderCode } = searchUrlParams(search)
    const CofunderCodeLocal = localStorage.getItem('CofunderCode')
    if (CofunderCode) {
      localStorage.setItem('CofunderCode', CofunderCode)
      CofunderCodeLocal && (window.location.href = 'https://app.usadrop.com?from=pay')
    } else if (code && state) {
      dispatch('login/getAuthBack', {
        Code: code,
        State: state
      }).then((res) => {
        const { Success, Result, ResData } = res || {}
        if (Success && Result) {
          removeToken()
          setToken(Result)
          this.getUserInfo()
          return
        }
        if (scope && scope.indexOf('google') > 0) {
          this.$message.error(this.$t('login.GoogleRegistration'))
          return
        }
        if (ResData) {
          const { UUId } = ResData
          this.$store.commit('setAuthInfo', ResData)
          UUId && this.oauthRegist(UUId)
        }
      })
    }
  },
  methods: {
    signIn(formName) {
      const {
        dispatch,
        state: { storeKey }
      } = this.$store
      let data = { email: this.formData.username, pwd: this.formData.password }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          dispatch('login/getLogin', data).then((res) => {
            const { Success } = res || {}
            if (Success) {
              window.location.href = 'https://app.usadrop.com/orders?tk=' + res.Result.Token
            } else {
              this.$message(res.ErrMsg || vm.$t('tips.failed'))
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    goRegister() {
      this.$router.push({
        path: 'register',
        query: {
          from: 'pay'
        }
      })
    },
    oauthRegist(UUId) {
      const { dispatch } = this.$store
      const CofunderCode = window.localStorage.getItem('CofunderCode')
      dispatch('login/getAuthRegister', { UUId, CofunderCode }).then((res = {}) => {
        const { Success, Result } = res || {}
        if (Success && Result) {
          removeToken()
          setToken(Result)
          this.getUserInfo()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
$color1: #bc0c34;
$color2: #15104b;
.ad-login {
  width: 100%;
  height: 100%;
  padding-top: 94px;
  background: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580786555_loginBg.png')
    no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-info {
    margin-right: 80px;
    text-align: left;
    margin-top: -6%;
    .logo1 {
      width: 225px;
      height: 189px;
      margin-bottom: 40px;
    }
    .logo2 {
      width: 428px;
      height: 42px;
      margin: 32px 0 32px 0;
    }
    .bottomContent {
      color: $color2;
      width: 876px;
      height: 240px;
      border-radius: 16px;
      background: #e0e3f2;
      text-align: center;
      padding: 24px 24px;
      .items {
        display: flex;
        justify-content: space-between;
        .content-item {
          width: 24.5%;
          display: flex;
          align-items: center;
          flex-direction: column;
          .circle {
            background: $color2;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-bottom: 8px;
            color: #ffffff;
            font-weight: 600;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .tipinfo {
            width: 83%;
          }
          .middle {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  .login-content {
    width: 503px;
    height: 708px;
    margin-top: -6%;
    background: #fff;
    border-radius: 30px;
    padding: 20px 40px;
    position: relative;
    .logo3{
      width: 45%;
      height: 100px;
      image-rendering: crisp-edges;
    }
    .login-head {
      font-size: 24px;
      color: #000000;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 40px;
      text-align: center;
    }
    .forget {
      width: 100%;
      text-align: right;
      position: absolute;
      top: 44px;
    }
    .option {
      width: 420px;
      height: 100px;
      margin: 0 auto 10px;
      padding-left: 3px;
      ::v-deep .el-input__inner {
        border-radius: 50px;
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
        width: 100%;
        height: 42px;
        border: 1px solid #cccccc;
      }
    }
    .login-bottom {
      margin-top: 80px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .el-button {
        width: 226px;
        height: 44px;
        background: $color2;
        font-size: 16px;
        margin-bottom: 8px;
        color: #ffffff;
        border-radius: 30px;
      }
      .small {
        font-size: 14px;
        color: #999999;
      }
    }
    .demo-ruleForm {
      ::v-deep .el-form-item {
        margin-bottom: 0 !important;
        .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }
  }
}
</style>
