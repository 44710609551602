var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content-left" }, [
      _c("div", { staticClass: "login-info" }, [
        _c("img", {
          staticClass: "logo1",
          attrs: {
            src: require("@/assets/imgs/NewLogo/left_img_ads_logo.svg"),
            alt: "",
          },
        }),
        _c("div", { staticClass: "welcome-title" }, [
          _c("div", { staticClass: "ft-56 fw-800 color2" }, [
            _vm._v("Welcome to"),
          ]),
          _c("div", { staticClass: "ft-56 fw-800 color1 mb-24" }, [
            _vm._v("USAdrop Payment Solution"),
          ]),
          _c("div", { staticClass: "color2 fw-500 ft-32" }, [
            _vm._v("USAdrop Payment Solution Service"),
          ]),
        ]),
        _c("img", {
          staticClass: "logo2",
          attrs: {
            src: require("@/assets/imgs/loginImgs/payWays.png"),
            alt: "",
          },
        }),
        _c("div", { staticClass: "bottomContent" }, [
          _c("div", { staticClass: "ft-24 mb-24" }, [
            _vm._v("4 main reasons why choose usadrop payment solution"),
          ]),
          _c("div", { staticClass: "items" }, [
            _c("div", { staticClass: "content-item" }, [
              _c("span", { staticClass: "circle" }, [_vm._v("1")]),
              _c("div", { staticClass: "ft-20 middle" }, [
                _vm._v("Seamless Dropship Payments"),
              ]),
              _c("div", { staticClass: "ft-12 tipinfo" }, [
                _vm._v("Swift transactions, no more waiting for your funds."),
              ]),
            ]),
            _c("div", { staticClass: "content-item" }, [
              _c("span", { staticClass: "circle" }, [_vm._v("2")]),
              _c("div", { staticClass: "ft-20 middle" }, [
                _vm._v("Global Dropshipping Access"),
              ]),
              _c("div", { staticClass: "ft-12 tipinfo" }, [
                _vm._v("Eliminate international payment hassles effortlessly."),
              ]),
            ]),
            _c("div", { staticClass: "content-item" }, [
              _c("span", { staticClass: "circle" }, [_vm._v("3")]),
              _c("div", { staticClass: "ft-20 middle" }, [
                _vm._v("Stability & Trust Gateway"),
              ]),
              _c("div", { staticClass: "ft-12 tipinfo" }, [
                _vm._v(
                  " Your reliable partner in continuous dropshipping success. "
                ),
              ]),
            ]),
            _c("div", { staticClass: "content-item" }, [
              _c("span", { staticClass: "circle" }, [_vm._v("4")]),
              _c("div", { staticClass: "ft-20 middle" }, [
                _vm._v("Diverse Payment Channels"),
              ]),
              _c("div", { staticClass: "ft-12 tipinfo" }, [
                _vm._v("Support for PayPal, Credit Cards, Klarna, and more."),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }