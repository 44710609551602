<template>
  <div class="ad-login">
    <div class="login-info">
      <img
        src="@/assets/imgs/NewLogo/left_img_ads_logo.svg"
        alt=""
        class="logo1" />
      <div class="welcome-title">
        <div class="ft-56 fw-800 color2">USAdrop</div>
        <div class="ft-56 fw-800 color1 mb-24">Payment Solution</div>
      </div>
      <div class="bottomContent">
        <div class="items1">
          <div class="payment_icon">
            <img src="https://file.securepago.com/ud/1737363984482_payment_icon.svg" alt="">
          </div>
          <div class="tip-text"></div>
          <div class="support_methods">
            Support for local payment methods in
            <span>LATAM</span>
          </div>
        </div>
        <div class="items2">
          <div class="payment_icon">
            <img src="https://file.securepago.com/ud/1737363985391_store_icon.svg" alt="">
          </div>
          <div class="tip-text"></div>
          <div class="support_methods">
            <span>500+ million</span>
            potential online buyers
          </div>
        </div>
        <div class="items2">
          <div class="payment_icon">
            <img src="https://file.securepago.com/ud/1737363985830_trend_icon.png" alt="">
          </div>
          <div class="tip-text"></div>
          <div class="support_methods">
            Estimated
            <span>$8.1 trillion</span>
            in online sales by 2026
          </div>
        </div>
      </div>
      <div class="opportunity">
        Don't miss the opportunity to tap into LATAM's fastest-growing
      </div>
      <div class="worldwide">
        e-commerce market worldwide and become a millionaire!
      </div>
    </div>
    <div class="login-content">
      <div class="ny_logo">
        <img
        src="@/assets/imgs/NewLogo/ny_logo.svg"
        alt=""/>
      </div>
      <p class="login-head">Welcome to USAdrop</p>
      <p class="payment_solution">Payment Solution</p>
      <el-form label-position="top" :rules="rules" :model="ruleForm" ref="formRef">
        <el-form-item label="E-mail" class="option" prop="email">
          <el-input v-model="ruleForm.email" />
        </el-form-item>
        <el-form-item label="Password" class="option" prop="password">
          <el-input v-model="ruleForm.password" :show-password="true" />
        </el-form-item>
        <div class="login-bottom">
          <div class="btns">
            <el-button @click="signIn('formRef')">Sign In</el-button>
            <div class="small">
              Don't have an account?
              <span style="cursor: pointer; color: #3f6aff" @click="goRegister">Sign Up Here</span>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { searchUrlParams, getFrom } from '@/utils/tools'
import { mapState } from 'vuex'
import { clearTTInfo, getTTSK, isTTLogin } from '@/utils/tthelper'
import { removeToken, setToken } from '@/utils/token'
export default {
  data() {
    return {
      ruleForm: {
        email: '',
        password: ''
      },
      rules: {
        email: [
          { required: true, message: 'Field  cannot be empty ', trigger: 'blur' },
          {
            type: 'email',
            message: 'Please enter the correct Email address',
            trigger: ['blur', 'change']
          }
        ],
        password: [{ required: true, message: 'Field  cannot be empty', trigger: 'blur' }]
      }
    }
  },
  computed: mapState({
    login: (state) => state.login
  }),

  created() {
    const { dispatch } = this.$store // 获取授权时获取的state code
    const search = decodeURIComponent(location.search)
    const { MarketingCode, code, state, scope } = searchUrlParams(search)
    const MarketingCodeLocal = localStorage.getItem('MarketingCode')
    if (MarketingCode) {
      localStorage.setItem('MarketingCode', MarketingCode)
      MarketingCodeLocal && (window.location.href = 'https://www.usadrop.com')
    } else if (code && state) {
      dispatch('login/getAuthBack', {
        Code: code,
        State: state
      }).then((res) => {
        const { Success, Result, ResData } = res || {}
        if (Success && Result) {
          removeToken()
          setToken(Result)
          this.getUserInfo()
          return
        }
        if (scope && scope.indexOf('google') > 0) {
          this.$message.error(this.$t('login.GoogleRegistration'))
          return
        }
        if (ResData) {
          const { UUId } = ResData
          this.$store.commit('setAuthInfo', ResData)
          UUId && this.oauthRegist(UUId)
        }
      })
    }
  },
  methods: {
    signIn() {
      removeToken() // 登录前清除过期token
      if (this.rememberMe) {
        localStorage.setItem('email', this.ruleForm.email)
        localStorage.setItem('password', this.ruleForm.password)
      } else {
        // 如果没有选择“记住我”，则清除本地存储中的用户名和密码信息
        localStorage.removeItem('email')
        localStorage.removeItem('password')
      }
      const {
        dispatch,
        state: { storeKey }
      } = this.$store
      const {
        ruleForm: { email, password }
      } = this
      if (!email || !password) return

      let loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      var data = { email, pwd: password }
      storeKey && (data.ShopifyTokenKey = this.$store.state.storeKey)
      dispatch('login/getLogin', data).then((res) => {
        const { Success } = res || {}
        if (Success) {
          setToken(res.Result)
          this.$store.commit('setStoreKey', '')
          this.getUserInfo()
          this.menterData()
        } else {
          this.$message(res.ErrMsg || vm.$t('tips.failed'))
        }
        setTimeout(() => {
          loading.close()
        }, 1000)
      })
    },
    goRegister() {
      this.$router.push({
        path: 'register',
        query: {
          from: 'nypayment'
        }
      })
    },
    oauthRegist(UUId) {
      const { dispatch } = this.$store
      const MarketingCode = window.localStorage.getItem('MarketingCode')
      dispatch('login/getAuthRegister', { UUId, MarketingCode }).then((res = {}) => {
        const { Success, Result } = res || {}
        if (Success && Result) {
          removeToken()
          setToken(Result)
          this.getUserInfo()
        }
      })
    },
    menterData() {
      /* 会员等级 */
      const { dispatch } = this.$store
      dispatch('login/getPaymentList').then((res) => {
        const { Result, Success } = res || {}
        if (Success && Result) {
          this.paymentList = res.Result
        }
      })
    },
    CheckGuide() {
      const { dispatch } = this.$store
      dispatch('login/getCheckGuide')
        .then((res) => {
          const { Success } = res || {}
          if (Success && res?.Result) {
            const {
              Result: { IsProfileComplete, IsStoreComplete }
            } = res
            if (!IsProfileComplete) {
              this.$router.push('StepOne')
              return
            }
            if (!IsStoreComplete) {
              this.$router.push('StepTwo')
              return
            }
            if (IsProfileComplete && IsStoreComplete) {
              if (isTTLogin()) {
                window.location.href = `${window.location.origin}/Store`
              } else {
                window.location.href = `${window.location.origin}/orders`
              }
            }
          }
        })
        .finally(() => {
          clearTTInfo()
        })
    },
    getUserInfo() {
      // 获取用户信息
      const { dispatch } = this.$store
      dispatch('login/getUserInfo').then((res) => {
        const { Success } = res || {}
        if (Success) {
          this.$store.commit('setUer', res.Result)
          this.CheckGuide()
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
$color1: #bc0c34;
$color2: #15104b;
.ad-login {
  width: 100%;
  height: 100%;
  padding-top: 94px;
  background: url('https://file.securepago.com/ud/1737362788181_ny_login_bg.png')
    no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-info {
    margin-right: 80px;
    text-align: left;
    margin-top: -6%;
    .logo1 {
      width: 225px;
      height: 189px;
      margin-bottom: 40px;
    }
    .logo2 {
      width: 428px;
      height: 42px;
      margin: 32px 0 32px 0;
    }
    .bottomContent {
      color: $color2;
      width: 876px;
      border-radius: 16px;
      text-align: center;
      .items1 {
        width: 690px;
        height: 55px;
        display: flex;
        background: #E0E3F2;
        border-radius: 16px;
      }
      .items2 {
        width: 690px;
        height: 55px;
        display: flex;
        background: #E0E3F2;
        border-radius: 16px;
        margin-top: 30px;
      }
      .payment_icon {
        margin-left: 30px;
        margin-top: 10px;
      }
      .tip-text {
        width: 2px;
        height: 30px;
        background: #231D65;
        margin-left: 10px;
        margin-top: 11px
      };
      .support_methods {
        font-size: 24px;
        font-weight: 800;
        color: #15104B;
        margin-top: 11px;
        margin-left: 20px;
        span {
          color: #C00A31;
        }
      }
    }
    .opportunity {
      font-size: 20px;
      font-weight: 800;
      color: #15104B;
      margin-top: 35px
    }
    .worldwide {
      font-size: 20px;
      font-weight: 800;
      color: #15104B;
      margin-top: 15px
    }
  }
  .login-content {
    width: 503px;
    height: 708px;
    margin-top: -6%;
    background: #fff;
    border-radius: 30px;
    padding: 20px 40px;
    position: relative;
    .ny_logo {
      text-align: center;
      margin-top: 15px;
      img {
        width: 200px;
        height: 71px;
      }
    }
    .login-head {
      font-size: 24px;
      color: #433759;
      font-weight: 400;
      margin-top: 30px;
      text-align: center;
    }
    .payment_solution {
      font-size: 24px;
      color: #433759;
      font-weight: 400;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 40px;
      font-family: 'Regular';
    }
    .forget {
      width: 100%;
      text-align: right;
      position: absolute;
      top: 44px;
    }
    .option {
      width: 420px;
      height: 100px;
      margin: 0 auto 10px;
      padding-left: 3px;
      ::v-deep .el-input__inner {
        border-radius: 50px;
        font-family: 'Regular';
        width: 100%;
        height: 42px;
        border: 1px solid #cccccc;
      }
    }
    .login-bottom {
      margin-top: 80px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .el-button {
        width: 226px;
        height: 44px;
        background: $color2;
        font-size: 16px;
        margin-bottom: 8px;
        color: #ffffff;
        border-radius: 30px;
        margin-left: 10px;
      }
      .small {
        font-size: 14px;
        color: #999999;
      }
    }
    .demo-ruleForm {
      ::v-deep .el-form-item {
        margin-bottom: 0 !important;
        .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }
  }
}
</style>
