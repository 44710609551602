<template>
  <div class="particulars">
    <div class="Bgrd"></div>
    <div class="activeName">
      <div class="guide_FillInYou">
        <p>{{ $t('guide.LinkStore') }}</p>
      </div>
      <div class="Inputshopify">
        <div class="LinkYourStoreContent">
          <p>Link your Shopify store now for a complimentary 7-day Pro membership!</p>
        </div>
        <div class="Confirm">
          <div class="link_button">
            <button @click="confirmStore">{{ $t('guide.LInkMyStore') }}</button>
          </div>
          <a href="https://shopify.pxf.io/aONbVN" target="_blank">
            <el-tooltip content="If you don't have a Shopify store, click this button to create one quickly." placement="bottom" popper-class="tooltip_width">
              <div class="bulid_shopify_btn">Bulid your shopify store</div>
            </el-tooltip>
          </a>
          <div class="ConfirmQuestion" @mouseenter="enter" @mouseleave="leave">
            <i class="el-icon-question"></i>
          </div>
          <div @click="CheckGuide" class="skipe_words">{{ $t('guide.skip') }}</div>
        </div>
        <div class="Contact" v-if="DisplayNotShopify">
          <p
            style="
              font-family: Regular;
              -webkit-font-smoothing: antialiased;
              cursor: pointer;
              color: #4c8bf5;
              border-bottom: 1px solid #542d8a;">
            {{ $t('guide.NotShopify') }}
          </p>
        </div>
      </div>
      <div class="Img">
        <img
          src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715582519023_StepStoreGroup.png"
          alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'StepTwo',
  data: () => {
    return {
      dispatch: null,
      input: '',
      CenterMessage: [],
      paymentList: [], // 获取会员列表
      DisplayNotShopify: false
    }
  },
  components: {},
  computed: mapState(['userInfo']),
  mounted() {
    this.menterData()
    /* 谷歌事件代码 */
    gtag('event', 'conversion', { send_to: 'AW-10836134056/VQ22CJOH4usYEKiRia8o' })
    gtag('event', 'conversion', { send_to: 'AW-11287337189/6YVECM6u5esYEOWxnIYq' })
  },
  created() {
    this.dispatch = this.$store.dispatch
    let params = this.$route.params
    let search = decodeURIComponent(location.search)
    if (params.type && search) {
      let authInfo = {}
      search = search.substring(1).split('&')
      search.map((item) => {
        let index = item.indexOf('=')
        let key = item.substring(0, index)
        let value = item.substring(index + 1).split('#')[0]
        authInfo[key] = value
      })
      if (params.type != 'addstore' && params.type != 'reauthorize') {
        return
      }
      this.getAuthInfo(params, authInfo)
    }
  },
  methods: {
    enter: function () {
      this.DisplayNotShopify = true
    },
    leave: function () {
      this.DisplayNotShopify = false
    },
    LinkWinners() {  /* 根据会员等级显示不同的页面 */
      this.CheckGuide()
    },
    menterData() { /* 会员列表页面 */
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          this.paymentList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getAuthInfo(params, authInfo) { // 授权信息接口解密
      this.dispatch('store/ReceiveShopifyTokenSt', {
          code: authInfo.code,
          hmac: authInfo.hmac,
          shop: authInfo.shop,
          timestamp: authInfo.timestamp
        })
        .then((res) => {
          authInfo.shop = authInfo.shop.replace('.myshopify.com', '')
          if (res.Success == true) {
            if (params.type == 'addstore') {
              this.AddStore(authInfo.shop, res.ResData)
            } else if (params.type == 'reauthorize') {
              this.btn(authInfo.shop, res.ResData)
            }
          } else {
            this.$message.error(this.$t('tips.storeAuthFail'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    btn(name, token) {
      this.dispatch('store/RefreshStoreSt', {
          StoreName: name,
          Token: token
        })
        .then((res) => {
          if (res.Success == true) {
            this.$message.success(this.$t('tips.storeReauthorizeStore'))
            window.location.href = '/guideSteps'
          } else {
            this.$message.error(this.$t('tips.storeAddFail'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    confirmStore() { // 点击添加店铺进入授权
      window.location.href = 'https://apps.shopify.com/usadrop'
    },
    actions(name, isAdd) { // 获取绑定店铺授权链接
      this.dispatch('store/GetOauthRedirectUrlSt', {
          storeName: name
        })
        .then((res) => {
          if (res.Success == true) {
            let oauthUrl = res.Result
            let locationUrl = window.location.origin
            let destinationUrl = ''
            if (isAdd) {
              destinationUrl = locationUrl + '/Store/addstore'
            } else {
              destinationUrl = locationUrl + '/Store/reauthorize'
            }
            destinationUrl = escape(destinationUrl)
            oauthUrl = escape(oauthUrl)
            let shopifyUrl = `https://webapi.usadrop.com/ShopifyOAuth/OAuth.html?shop=${name}&destinationUrl=${destinationUrl}&oauthUrl=${oauthUrl}`
            window.location.href = shopifyUrl
          } else {
            this.$message.error(this.$t('tips.storeLinkError'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    CheckGuide() { /* 引导页面 */
      this.dispatch('guide/checkGuideGu')
        .then((res) => {
          // this.$router.push('/orders')
          window.location.href = `${window.location.origin}/orders`
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.particulars {
  width: 100%;
  position: relative;
  background: #ffffff;
  min-height: 100vh;
  font-family: 'Regular';
}
.activeName {
  margin: auto;
  padding-bottom: 32px;
  width: 1320px;
  border-radius: 10px !important;
  position: relative;
  background-color: #fff;
  font-family: 'Regular';
}
.Bgrd {
  width: 100%;
  height: 130px;
  position: relative;
  background: linear-gradient(132deg, #1b1941, #22224b, #202251);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.guide_FillInYou p {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  position: absolute;
  left: 500px;
  top: -140px;
}
.Contact {
  position: absolute;
  top: 115px;
  left: 280px;
  font-size: 16px;
  span:nth-child(1) {
    color: #4c8bf5;
  }
  span:nth-child(2) {
    display: block;
    position: absolute;
    top: 0px;
    right: -30px;
    font-size: 22px;
    color: #4c8bf5;
  }
}
.Contact:hover {
  transform: scale(1.1);
}
.Confirm {
  display: flex;
  .link_button  {
    button {
      width: 150px;
      height: 40px;
      line-height: 40px;
      background: #bc0c34;
      border-radius: 50px;
      border: 0;
      margin-top: 20px;
      margin-left: 350px;
      color: #ffffff;
      font-size: 16px;
      &:hover {
        color: #fff;
        opacity: 0.8;
      }
    }
  }
  .bulid_shopify_btn {
    width: 250px;
    height: 40px;
    line-height: 40px;
    background: #93df89;
    border-radius: 50px;
    border: 0;
    margin-top: 20px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    margin-left: 30px;
  }
  ::v-deep .el-step__head {
    margin-left: 50px !important;
  }
  ::v-deep .ConfirmQuestion .el-icon-question {
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 32px;
  }
  ::v-deep .ConfirmQuestion .el-icon-question:hover {
    font-size: 22px;
    cursor: pointer;
  }
  .skipe_words {
    width: 50px;
    height: 30px;
    margin-top: 28px;
    margin-left: 15px;
    color: #4c8bf5;
    cursor: pointer;
    font-style: italic;
    font-size: 16px;
    &:hover {
      transform: translate(0px, 0px);
      font-size: 20px;
    }
  }
}
.Img {
  margin-top: 50px;
  margin-left: 170px;
  img {
    width: 1000px;
    height: 500px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
}
::v-deep .el-step__icon {
  position: relative;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 14px;
  box-sizing: border-box;
  background: #fff;
  transition: 0.15s ease-out;
}

.LinkYourStoreContent {
  text-align: center;
  p {
    font-size: 30px;
    color: #bc0c34;
    margin-top: 50px;
  }
}
</style>
