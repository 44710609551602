<template>
  <div class="content-left">
    <div class="login-info">
      <img :src="logoImg[formLink]" alt="" class="logo1" />
      <div class="welcome-title">
        <div class="ft-56 fw-800 color2" style="margin-top: 50px">Welcome to</div>
        <div class="ft-56 fw-800 color1 mb-24">USAdrop Ads Agency</div>
        <div class="color2 fw-500 mb-24 ft-20">USAdrop Facebook Ads Account</div>
        <div class="ft-56 fw-800 color2 mb-48">USAdrop Ads-agency Service</div>
        <div class="btnContent">
          <div class="btnInfo">
            <img
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581009301_icon1.png"
              alt=""
            />
            <div class="tip-text">Up to <span class="color1">5%</span> Cash Back</div>
          </div>
          <div class="btnInfo">
            <img
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581009336_icon2.png"
              alt=""
            />
            <div class="tip-text"><span class="color1">0%</span> Charging Free</div>
          </div>
          <div class="btnInfo">
            <img
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581009409_icon3.png"
              alt=""
            />
            <div class="tip-text">Stable Ads Account</div>
          </div>
          <div class="btnInfo">
            <img
              src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581009445_icon4.png"
              alt=""
            />
            <div class="tip-text">No Spending Limits</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFrom } from '@/utils/tools'
export default {
  data: () => {
    return {
      logoImg: {
        'app.usadrop.com': require('@/assets/imgs/NewLogo/left_img_ads_logo.svg'),
        'vn.usadrop.com': require('@/assets/imgs/NewLogo/VNLogoSlice.png')
      },
      formLink: getFrom()
    }
  }
}
</script>
<style scoped lang="scss">
$color1: #bc0c34;
$color2: #15104b;
.login-info {
  margin-right: 80px;
  text-align: left;
  margin-top: -6%;
  .logo1 {
    width: 225px;
    height: 189px;
    margin-top: 30px;
    img {
      width: 100%;
    }
  }
  .welcome-title {
  }
  .WelcomeTo {
    margin-top: 50px;
  }
}
.ad-login {
  width: 100%;
  height: 100%;
  padding-top: 94px;
  background: url('~@/assets/imgs/loginBg.png') no-repeat;
  background-size: cover;
  //   text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-content {
    width: 503px;
    height: 708px;
    margin-top: -6%;
    background: #fff;
    border-radius: 30px;
    padding: 20px 40px;
    position: relative;
    .logo2 {
      width: 90px;
      height: 64px;
      position: absolute;
      left: 40px;
      margin-bottom: 30px;
    }
    .login-head {
      font-size: 24px;
      color: #000000;
      font-weight: 500;
      margin-top: 104px;
      margin-bottom: 40px;
      text-align: center;
    }
    .forget {
      width: 100%;
      text-align: right;
      position: absolute;
      top: 44px;
    }
    .option {
      width: 420px;
      height: 100px;
      margin: 0 auto 10px;
      padding-left: 3px;
      ::v-deep .el-input__inner {
        // box-shadow: none;
        border-radius: 50px;
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
        width: 100%;
        height: 42px;
        border: 1px solid #cccccc;
        // .el-input__inner {
        //   border-radius: 50px;
        //   font-family: 'Regular';
        //   -webkit-font-smoothing: antialiased;
        //   width: 100%;
        //   height: 48px;
        //   border: 1px solid #cccccc;
        // }
      }
    }
    .login-bottom {
      margin-top: 80px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .el-button {
        width: 226px;
        height: 44px;
        background: $color2;
        font-size: 16px;
        margin-bottom: 8px;
        color: #ffffff;
        border-radius: 30px;
      }
      .small {
        font-size: 14px;
        color: #999999;
      }
    }

    .demo-ruleForm {
      ::v-deep .el-form-item {
        margin-bottom: 0 !important;
        .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }
  }
}
.btnContent {
  display: flex;
  width: 620px;
  flex-wrap: wrap;
  justify-content: space-between;
  .btnInfo {
    background: #e0e3f2;
    box-shadow: 2px 3px 4px 0px #231d6540;
    width: 260px;
    border-radius: 16px;
    margin-bottom: 40px;
    font-size: 16px;
    color: $color2;
    display: flex;
    align-items: center;
    padding: 8px;
    img {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
    .tip-text {
      padding-left: 8px;
      color: $color2;
      font-weight: 600;
      border-left: 1.5px solid $color2;
    }
  }
}
</style>
