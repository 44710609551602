<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName">
      <div class="display_area">
        <div class="focus">
          <el-carousel :interval="5000" arrow="always" height="320px" ref="swiper">
            <el-carousel-item v-for="item in imagesUrl" :key="item.id" class="swiper">
              <img :src="item.PicUrl" alt="none" class="img" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="chart">
        <button class="seven" :class="[dataTime == '0' ? 'selected' : '']" @click="setTime('0')">
          {{ $t('dashboard.seven') }}
        </button>
        <button class="month" :class="[dataTime == '1' ? 'selected' : '']" @click="setTime('1')">
          {{ $t('dashboard.month') }}
        </button>
        <div class="paid_orders_number">{{ $t('dashboard.PaidOrdersNumber') }}</div>
        <echarts class="echarts" :dayType="dataTime"></echarts>
        <div class="paid_days">{{ $t('dashboard.PaidDays') }}</div>
      </div>
      <dashboardBalance></dashboardBalance>
    </div>
  </div>
</template>
<script>
import echarts from '@/components/echarts.vue'
import dashboardBalance from './dashboardBalance.vue'
import { mapState } from 'vuex'
export default {
  name: 'Dashboard',
  data: () => {
    return {
      dispatch: null,
      page: 'dashboard',
      imgindex: '0', //控制变量，默认显示第一张
      imagesUrl: [],
      flag: true,
      timer: null, //定时器
      dataTime: Number(0) //图表时间
    }
  },
  components: {
    echarts,
    dashboardBalance
  },
  computed: mapState(['userInfo', 'theme']),
  created() {
    this.dispatch = this.$store.dispatch
    this.getData()
  },
  methods: {
    select(index) {
      this.imgindex = index
      this.$refs.swiper.setActiveItem(index)
    },
    imgChange(index) {
      this.imgindex = index
    },
    recharge() {
      this.$router.push('wallet')
    },
    upgrade() {
      this.$router.push('member')
    },
    setTime(time) {
      this.dataTime = Number(time)
    },
    getData() {
      this.dispatch('home/GetBannersHm')
        .then((res) => {
          this.imagesUrl = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
<style scoped lang="scss">
.particulars {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  font-family: 'Regular';
}
.activeName {
  width: 1320px;
  border-radius: 10px;
  line-height: 20px;
  position: relative;
  padding-bottom: 32px;
  font-family: 'Regular';
}
.display_area {
  height: 350px;
  overflow: hidden;
  position: relative;
}
.focus {
  position: absolute;
  top: 0px;
  right: 0;
  width: 100%;
  height: 350px;
  border-radius: 10px;
}
.img {
  float: left;
}
.trans {
  transition: all 0.5s;
}
::v-deep .el-carousel__arrow {
  z-index: 5;
}
.swiper {
  width: 100%;
  height: 320px;
  border-radius: 10px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.chart {
  width: 1000px;
  height: 400px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  position: relative;
}
.chart .seven:hover {
  background: #15104b;
  color: #fff;
}
.chart .month:hover {
  background: #15104b;
  color: #fff;
}
.chart .seven {
  position: absolute;
  top: 10px;
  right: 170px;
  width: 120px;
  height: 38px;
  border-radius: 50px;
  text-align: center;
  line-height: 38px;
  padding: 0;
  z-index: 1;
  border: 0;
  background: #f5f5f5;
  color: #858585;
  font-size: 14px;
}
.chart .month {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 120px;
  height: 38px;
  background: #f5f5f5;
  color: #858585;
  border-radius: 50px;
  text-align: center;
  line-height: 38px;
  padding: 0;
  z-index: 1;
  border: 0;
  font-size: 14px;
}
.chart .paid_orders_number {
  position: absolute;
  top: 10px;
  left: 30px;
  height: 38px;
  color: #000;
  text-align: center;
  line-height: 38px;
  padding: 0;
  font-size: 14px;
}
.chart .paid_days {
  position: absolute;
  bottom: 0px;
  right: 5px;
  width: 120px;
  height: 38px;
  color: #000;
  text-align: center;
  line-height: 38px;
  padding: 0;
  font-size: 14px;
}
.chart .selected {
  background: #15104b;
  color: #ffffff;
}
.chart .all {
  position: absolute;
  top: 32px;
  left: 1210px;
  width: 50px;
  height: 38px;
  background: #f5f5f5;
  border-radius: 12px;
  text-align: center;
  line-height: 38px;
  padding: 0;
  z-index: 111111;
  border: 0;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.chart .echarts {
  padding-top: 40px;
}
</style>
