var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ad-login" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "login-content" },
      [
        _vm._m(1),
        _c("p", { staticClass: "login-head" }, [_vm._v("Welcome to USAdrop")]),
        _c("p", { staticClass: "payment_solution" }, [
          _vm._v("Payment Solution"),
        ]),
        _c(
          "el-form",
          {
            ref: "formRef",
            attrs: {
              "label-position": "top",
              rules: _vm.rules,
              model: _vm.ruleForm,
            },
          },
          [
            _c(
              "el-form-item",
              {
                staticClass: "option",
                attrs: { label: "E-mail", prop: "email" },
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.ruleForm.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "email", $$v)
                    },
                    expression: "ruleForm.email",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "option",
                attrs: { label: "Password", prop: "password" },
              },
              [
                _c("el-input", {
                  attrs: { "show-password": true },
                  model: {
                    value: _vm.ruleForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "password", $$v)
                    },
                    expression: "ruleForm.password",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "login-bottom" }, [
              _c(
                "div",
                { staticClass: "btns" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.signIn("formRef")
                        },
                      },
                    },
                    [_vm._v("Sign In")]
                  ),
                  _c("div", { staticClass: "small" }, [
                    _vm._v(" Don't have an account? "),
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer", color: "#3f6aff" },
                        on: { click: _vm.goRegister },
                      },
                      [_vm._v("Sign Up Here")]
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-info" }, [
      _c("img", {
        staticClass: "logo1",
        attrs: {
          src: require("@/assets/imgs/NewLogo/left_img_ads_logo.svg"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "welcome-title" }, [
        _c("div", { staticClass: "ft-56 fw-800 color2" }, [_vm._v("USAdrop")]),
        _c("div", { staticClass: "ft-56 fw-800 color1 mb-24" }, [
          _vm._v("Payment Solution"),
        ]),
      ]),
      _c("div", { staticClass: "bottomContent" }, [
        _c("div", { staticClass: "items1" }, [
          _c("div", { staticClass: "payment_icon" }, [
            _c("img", {
              attrs: {
                src: "https://file.securepago.com/ud/1737363984482_payment_icon.svg",
                alt: "",
              },
            }),
          ]),
          _c("div", { staticClass: "tip-text" }),
          _c("div", { staticClass: "support_methods" }, [
            _vm._v(" Support for local payment methods in "),
            _c("span", [_vm._v("LATAM")]),
          ]),
        ]),
        _c("div", { staticClass: "items2" }, [
          _c("div", { staticClass: "payment_icon" }, [
            _c("img", {
              attrs: {
                src: "https://file.securepago.com/ud/1737363985391_store_icon.svg",
                alt: "",
              },
            }),
          ]),
          _c("div", { staticClass: "tip-text" }),
          _c("div", { staticClass: "support_methods" }, [
            _c("span", [_vm._v("500+ million")]),
            _vm._v(" potential online buyers "),
          ]),
        ]),
        _c("div", { staticClass: "items2" }, [
          _c("div", { staticClass: "payment_icon" }, [
            _c("img", {
              attrs: {
                src: "https://file.securepago.com/ud/1737363985830_trend_icon.png",
                alt: "",
              },
            }),
          ]),
          _c("div", { staticClass: "tip-text" }),
          _c("div", { staticClass: "support_methods" }, [
            _vm._v(" Estimated "),
            _c("span", [_vm._v("$8.1 trillion")]),
            _vm._v(" in online sales by 2026 "),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "opportunity" }, [
        _vm._v(
          " Don't miss the opportunity to tap into LATAM's fastest-growing "
        ),
      ]),
      _c("div", { staticClass: "worldwide" }, [
        _vm._v(" e-commerce market worldwide and become a millionaire! "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ny_logo" }, [
      _c("img", {
        attrs: { src: require("@/assets/imgs/NewLogo/ny_logo.svg"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }