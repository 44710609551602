var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header" }, [
    _c(
      "div",
      {
        staticClass: "headerContent",
        class: [
          _vm.BindStore ? "HeaderTitle" : "HeaderTitleTop",
          "headerCommon",
        ],
      },
      [
        _c(
          "div",
          { staticClass: "breadContent" },
          [_c("breadCrumb", { staticClass: "breadCrumb" })],
          1
        ),
        _c(
          "div",
          { staticClass: "multitudinous_icon" },
          [
            _c(
              "div",
              { staticClass: "nypayment-btn", on: { click: _vm.go2NYPayment } },
              [
                !_vm.isLoading
                  ? _c("p", [_vm._v("NY Payment")])
                  : _c("p", [
                      _vm._v("NY Payment"),
                      _c("i", { staticClass: "el-icon-loading" }),
                    ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "UsaDropProductBtn",
                on: { click: _vm.UsaDropProductBtn },
              },
              [_c("p", [_vm._v("USAdrop Products")])]
            ),
            _c(
              "div",
              { staticClass: "Co_Funder", on: { click: _vm.CoFunderBtn } },
              [_c("p", [_vm._v("Co-Founder")])]
            ),
            _vm.showAdsAgency
              ? _c(
                  "div",
                  { staticClass: "Payment", on: { click: _vm.AdsAgencyBtn } },
                  [_c("p", [_vm._v("Ads-agency")])]
                )
              : _vm._e(),
            _vm.CenterMessage.GradeId == 1
              ? _c(
                  "div",
                  {
                    staticClass: "SliceSmallBell_img",
                    on: { click: _vm.toggleNotice },
                  },
                  [
                    _vm._m(0),
                    !!_vm.memberVo.LastedNews &&
                    !_vm.memberVo.LastedNews.IsExpire
                      ? _c("div", { staticClass: "toggleNoticeBlock" })
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm.CenterMessage.GradeId == 3 || _vm.CenterMessage.GradeId == 2
              ? _c(
                  "div",
                  {
                    staticClass: "SliceSmallBell_img_member",
                    on: { click: _vm.toggleNotice },
                  },
                  [
                    _vm._m(1),
                    !!_vm.memberVo.LastedNews &&
                    !_vm.memberVo.LastedNews.IsExpire
                      ? _c("div", { staticClass: "toggleNoticeBlock" })
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm.CenterMessage.GradeId == 1
              ? _c(
                  "div",
                  {
                    staticClass: "SliceProfile_img_GradeIdOne",
                    on: { click: _vm.ProfileBtn },
                  },
                  [
                    _c("img", {
                      staticClass: "avater",
                      attrs: {
                        src: _vm.avater
                          ? _vm.baseApiHost + _vm.avater
                          : require("@/assets/imgs/Bank-Transfer/headportrait.png"),
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm.CenterMessage.GradeId == 2
              ? _c(
                  "div",
                  {
                    staticClass: "SliceProfile_GradeId_img",
                    on: { click: _vm.ProfileBtn, mouseenter: _vm.onMouseOver },
                  },
                  [
                    _c("img", {
                      staticClass: "avater",
                      attrs: {
                        src: _vm.avater
                          ? _vm.baseApiHost + _vm.avater
                          : require("@/assets/imgs/Bank-Transfer/headportrait.png"),
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm.CenterMessage.GradeId == 3
              ? _c(
                  "div",
                  {
                    staticClass: "SliceProfile_img",
                    on: {
                      click: _vm.ProfileBtn,
                      mouseenter: _vm.onMouseOverSVIP,
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "avater",
                      attrs: {
                        src: _vm.avater
                          ? _vm.baseApiHost + _vm.avater
                          : require("@/assets/imgs/Bank-Transfer/headportrait.png"),
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "el_menu", attrs: { trigger: "click" } },
              [
                _c(
                  "el-dropdown",
                  [
                    _c("p", { staticClass: "el-dropdown-link" }, [
                      _vm._v(" " + _vm._s(_vm.userInfo.RealName)),
                      _c("i", {
                        staticClass: "el-icon-caret-bottom el-icon--right",
                      }),
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            nativeOn: {
                              click: function ($event) {
                                return _vm.linkToRouter("wallet")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/SideBar/wallet-right-menu.png"),
                                alt: "",
                              },
                            }),
                            _c("span", [_vm._v("Wallet")]),
                          ]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            nativeOn: {
                              click: function ($event) {
                                return _vm.linkToRouter("affiliate")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/SideBar/Affiliate-right-menu.png"),
                                alt: "",
                              },
                            }),
                            _c("span", { staticClass: "Walletword" }, [
                              _vm._v("Affiliate"),
                            ]),
                          ]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            nativeOn: {
                              click: function ($event) {
                                return _vm.linkToRouter("Video")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/SideBar/video-right-menu.png"),
                                alt: "",
                              },
                            }),
                            _c("span", { staticClass: "Walletword" }, [
                              _vm._v("Video"),
                            ]),
                          ]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            nativeOn: {
                              click: function ($event) {
                                return _vm.linkToRouter("HelpCenter")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/SideBar/helpcenter-right-menu.png"),
                                alt: "",
                              },
                            }),
                            _c("span", { staticClass: "Walletword" }, [
                              _vm._v("Help Center"),
                            ]),
                          ]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            nativeOn: {
                              click: function ($event) {
                                return _vm.linkToRouter("sourcing-history")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/SideBar/sourcing.png"),
                                alt: "",
                              },
                            }),
                            _c("span", { staticClass: "Walletword" }, [
                              _vm._v("Sourcing History"),
                            ]),
                          ]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            staticClass: "dividedDropItem",
                            nativeOn: {
                              click: function ($event) {
                                return _vm.linkToRouter("login")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/imgs/SideBar/logout-right-menu.png"),
                                alt: "",
                              },
                            }),
                            _c("span", { staticClass: "Walletword" }, [
                              _vm._v("Log Out"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("noticeCom", { attrs: { notice: _vm.isNotice } }),
            _c(
              "div",
              { staticClass: "CanCelMemberPop" },
              [
                _vm.ShowVIPPop
                  ? [
                      _vm.CenterMessage.GradeId == 2
                        ? _c(
                            "div",
                            {
                              staticClass: "VIPBox",
                              on: { mouseleave: _vm.onMouseOut },
                            },
                            [
                              _c("div", { staticClass: "VIPBox_title" }, [
                                _vm._m(2),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("HeaderMember.Professional"))
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "HeaderMember_membershipExpire",
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("HeaderMember.membershipExpire")
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "memberVo_EffectiveEndDate_box",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "memberVo_EffectiveEndDate",
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.memberVo.EffectiveEndDate
                                          ) + "."
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "HeaderMember_Membership" },
                                    [
                                      _vm._v("  "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("HeaderMember.Membership")
                                          )
                                        ),
                                      ]),
                                      _vm._v("  "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("HeaderMember.willBeRenewed")
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "Automatically_uponExpiration" },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("HeaderMember.Automatically")
                                      )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("HeaderMember.uponExpiration")
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm.MenterDataList.IsAdmin == true
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "HeaderMember_Downgrade_btn",
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "comBtn btn-bg0",
                                          on: {
                                            click: _vm.OpenShowMemberPopVIPBox,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("HeaderMember.Downgrade")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.ShowSVIPPop
                  ? [
                      _vm.CenterMessage.GradeId == 3
                        ? _c(
                            "div",
                            {
                              staticClass: "VIPBox",
                              on: { mouseleave: _vm.onMouseSVIPout },
                            },
                            [
                              _c("div", { staticClass: "VIPBox_title" }, [
                                _vm._m(3),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("HeaderMember.Premium"))
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "HeaderMember_membershipExpire",
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("HeaderMember.membershipExpire")
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "memberVo_EffectiveEndDate_box",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "memberVo_EffectiveEndDate_SVIP",
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.memberVo.EffectiveEndDate
                                          ) + "."
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "HeaderMember_Membership_SVIP",
                                    },
                                    [
                                      _vm._v("  "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("HeaderMember.Membership")
                                          )
                                        ),
                                      ]),
                                      _vm._v("  "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("HeaderMember.willBeRenewed")
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "Automatically_uponExpiration_SVIP",
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("HeaderMember.Automatically")
                                      )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("HeaderMember.uponExpiration")
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm.MenterDataList.IsAdmin == true
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "HeaderMember_Downgrade_btn",
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "comBtn btn-bg0",
                                          on: {
                                            click: _vm.OpenShowMemberPopSVIPBox,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("HeaderMember.Downgrade")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c("div", { staticClass: "ShowMemberPop" }, [
              _vm.ShowMemberPopVIPBox
                ? _c("div", [
                    _vm.CenterMessage.GradeId == 2
                      ? _c("div", { staticClass: "ShowMemberPopVIP_Box" }, [
                          _c(
                            "div",
                            { staticClass: "ShowMemberPopVIP_Content" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "membershipFee_membershipFollows",
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("HeaderMember.membershipFee")
                                      )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("HeaderMember.membershipFollows")
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "VIPMembershipBenefits" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "memberInfoVIP_box" },
                                    [
                                      _vm._m(4),
                                      _vm._l(
                                        _vm.memberInfo[1].detail,
                                        function (member, i) {
                                          return _c("div", { key: member.Id }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "Winner_Product_Quote",
                                              },
                                              [
                                                _vm.memberInfo[1].detail[i]
                                                  .value == "true"
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass: "checkbox",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-check",
                                                          staticStyle: {
                                                            color: "#3c763d",
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "MemberValue",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.memberInfo[1]
                                                              .detail[i].value
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "MemberBenefitsDescription",
                                    },
                                    [
                                      _vm._m(5),
                                      _vm._l(
                                        _vm.memberInfo[1].detail,
                                        function (member) {
                                          return _c("div", { key: member.Id }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "Winner_Product_Quote",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "Winner_Product_Name",
                                                  },
                                                  [_vm._v(_vm._s(member.name))]
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "BasicMembership" },
                                    [
                                      _vm._m(6),
                                      _vm._l(
                                        _vm.memberInfo[0].detail,
                                        function (member) {
                                          return _c(
                                            "div",
                                            { key: member.GradeName },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "Winner_Product_Quote",
                                                },
                                                [
                                                  member.value == "true"
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "checkbox",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-check",
                                                            staticStyle: {
                                                              color: "#3c763d",
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : member.value == "false"
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "checkbox",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "#000",
                                                              },
                                                            },
                                                            [_vm._v("--")]
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "MemberValue",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(member.value)
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "Back_Downgrade_Btn_Box" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "HeaderMember_Back",
                                      on: { click: _vm.HeaderMemberClose },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("HeaderMember.Back"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "HeaderMember_Downgrade",
                                      on: { click: _vm.HeaderMemberDowngrade },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("HeaderMember.Downgrade"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.ShowMemberPopSVIPBox
                ? _c("div", [
                    _vm.CenterMessage.GradeId == 3
                      ? _c("div", { staticClass: "ShowMemberPopVIP_Box" }, [
                          _c(
                            "div",
                            { staticClass: "ShowMemberPopVIP_Content" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "membershipFee_membershipFollows",
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("HeaderMember.membershipFee")
                                      )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("HeaderMember.membershipFollows")
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "VIPMembershipBenefits" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "memberInfoVIP_box",
                                      staticStyle: { background: "#fffbf3" },
                                    },
                                    [
                                      _vm._m(7),
                                      _vm._l(
                                        _vm.memberInfo[1].detail,
                                        function (member, i) {
                                          return _c("div", { key: member.Id }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "Winner_Product_Quote",
                                              },
                                              [
                                                _vm.memberInfo[2].detail[i]
                                                  .value == "true"
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass: "checkbox",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-check",
                                                          staticStyle: {
                                                            color: "#3c763d",
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "MemberValue",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.memberInfo[2]
                                                              .detail[i].value
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "MemberBenefitsDescription",
                                    },
                                    [
                                      _vm._m(8),
                                      _vm._l(
                                        _vm.memberInfo[1].detail,
                                        function (member) {
                                          return _c("div", { key: member.Id }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "Winner_Product_Quote",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "Winner_Product_Name",
                                                  },
                                                  [_vm._v(_vm._s(member.name))]
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "BasicMembership" },
                                    [
                                      _vm._m(9),
                                      _vm._l(
                                        _vm.memberInfo[0].detail,
                                        function (member) {
                                          return _c(
                                            "div",
                                            { key: member.GradeName },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "Winner_Product_Quote",
                                                },
                                                [
                                                  member.value == "true"
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "checkbox",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-check",
                                                            staticStyle: {
                                                              color: "#3c763d",
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : member.value == "false"
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "checkbox",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "#000",
                                                              },
                                                            },
                                                            [_vm._v("--")]
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "MemberValue",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(member.value)
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "Back_Downgrade_Btn_Box" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "HeaderMember_Back",
                                      on: { click: _vm.HeaderMemberCloseSVIP },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("HeaderMember.Back"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "HeaderMember_Downgrade",
                                      on: {
                                        click: _vm.HeaderMemberDowngradeSVIP,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("HeaderMember.Downgrade"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _vm.ShowCancellationBox
              ? _c("div", { staticClass: "cancellation_box" }, [
                  _vm.CenterMessage.GradeId == 2
                    ? _c("div", { staticClass: "cancellation_vip_box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "closeBtn",
                            on: { click: _vm.CloseVIP },
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        ),
                        _c(
                          "div",
                          { staticClass: "HeaderMember_YouCancelled" },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("HeaderMember.YouCancelled"))
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "membershipFeeYour_thismonthwill" },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("HeaderMember.membershipFeeYour"))
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("HeaderMember.thismonthwill"))
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "chargednormal_wewill" }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("HeaderMember.chargednormal"))
                            ),
                          ]),
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("HeaderMember.wewill"))),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "HeaderMember_automaticallyfees" },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("HeaderMember.automaticallyfees"))
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "HeaderMember_nextmonth" }, [
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("HeaderMember.nextmonth"))),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "HeaderMember_OK" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "HeaderMember_OK_Btn",
                                on: { click: _vm.handleConfirm },
                              },
                              [_vm._v(_vm._s(_vm.$t("HeaderMember.OK")))]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.CenterMessage.GradeId == 3
                    ? _c("div", { staticClass: "cancellation_Svip_box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "closeBtn",
                            on: { click: _vm.CloseSVIP },
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        ),
                        _c(
                          "div",
                          { staticClass: "HeaderMember_YouCancelled" },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("HeaderMember.YouCancelled"))
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "membershipFeeYour_thismonthwill" },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("HeaderMember.membershipFeeYour"))
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("HeaderMember.thismonthwill"))
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "chargednormal_wewill" }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("HeaderMember.chargednormal"))
                            ),
                          ]),
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("HeaderMember.wewill"))),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "HeaderMember_automaticallyfees" },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("HeaderMember.automaticallyfees"))
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "HeaderMember_nextmonth" }, [
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("HeaderMember.nextmonth"))),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "HeaderMember_OK" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "HeaderMember_OK_Btn",
                                on: { click: _vm.handleConfirm },
                              },
                              [_vm._v(_vm._s(_vm.$t("HeaderMember.OK")))]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("@/assets/imgs/SideBar/SliceSmallBell.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("@/assets/imgs/SideBar/SliceSmallBell.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/NewLogo/MemberVIPSlice16.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/NewLogo/MemberSVIPSlice16.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "BigMemberImgVIPSliceImg" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/BigMemberImgVIPSlice.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ArrowSliceImg" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/ArrowSlice.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "BigMemberImgVIPSliceImg" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/MemberSliceJiChu1.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "BigMemberImgVIPSliceImg" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/BigMemberImgSVIPSlice.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ArrowSliceImg" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/ArrowSlice.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "BigMemberImgVIPSliceImg" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/imgs/ProfileSub/MemberSliceJiChu1.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }