import request from '@/utils/request';

export const GetUser = (data) => request({
    method: "post",
    url: '/api/LhUser/GetUser',
    data
  });

  export const homeIndex = (params) => request({
    method: "post",
    url: '/home/index',
    params
  });
  export const OrderLineChart = (data) => request({
    method: "post",
    url: '/api/Chart/OrderLineChart',
    data
  });
  export const GetBanners = (params) => request({
    method: "get",
    url: '/api/Common/GetBanners',
    params
  });
  export const OAuth2CallBack = (params) => request({
    method: "post",
    url: '/api/OAuth2/CallBack',
    params
  });
  export const GetVerifyCode = (params) => request({
    method: "post",
    url: '/api/Login/GetVerifyCode',
    params
  });
  export const LhFirstStepRecordAdd = (params) => request({
    method: "post",
    url: '/api/LhFirstStepRecord/Add',
    params
  });
  export const CheckMoblieExist = (params) => request({
    method: "post",
    url: '/api/Login/CheckMoblieExist',
    params
  });
  /* 获取NY token */
  export const NyToken = (data) => request({
    method: "post",
    url: '/api/Login/NyToken',
    data
  });
