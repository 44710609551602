<template>
  <div class="loginUI">
    <template v-if="currentSource === 'ud'">
      <Ud></Ud>
    </template>
     <template v-if="currentSource === 'ads'">
      <Ads></Ads>
    </template>
     <template v-if="currentSource === 'partner'">
      <Partner></Partner>
    </template>
      <template v-if="currentSource === 'qf'">
      <Qf></Qf>
    </template>
    <template v-if="currentSource === 'pay'">
      <Pay></Pay>
    </template>
    <template v-if="currentSource === 'acadamy'">
      <Acadamy></Acadamy>
    </template>
    <template v-if="currentSource === 'nypayment'">
      <Ny></Ny>
    </template>
  </div>
</template>
<script>
import Ud from './components/ud.vue'
import Partner from './components/partner.vue'
import Qf from './components/qf.vue'
import Pay from './components/pay.vue'
import Ads from './components/ads.vue'
import Acadamy from './components/acadamy.vue'
import Ny from './components/ny.vue'
import {useSearch} from '@/utils/tools'

export default {
  components:{Ud,Partner,Ads,Qf,Pay,Acadamy,Ny},
  data(){
    return{
      currentSource:'ud' // ud：0，ads:1,partner:2
    }
  },
  created(){
    this.currentSource = useSearch('from') ?? 'ud'
  }

}
</script>
<style lang="scss" scoped>
.loginUI {
  height: 100vh;
}
</style>
