var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loginUI" },
    [
      _vm.currentSource === "ud" ? [_c("Ud")] : _vm._e(),
      _vm.currentSource === "ads" ? [_c("Ads")] : _vm._e(),
      _vm.currentSource === "partner" ? [_c("Partner")] : _vm._e(),
      _vm.currentSource === "qf" ? [_c("Qf")] : _vm._e(),
      _vm.currentSource === "pay" ? [_c("Pay")] : _vm._e(),
      _vm.currentSource === "acadamy" ? [_c("Acadamy")] : _vm._e(),
      _vm.currentSource === "nypayment" ? [_c("Ny")] : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }