var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("lastedNews"),
      _c(
        "div",
        { staticClass: "scroll", class: { show: _vm.isActive } },
        [
          _c(
            "div",
            {
              staticClass: "particulars",
              class: [_vm.theme === "common" ? "" : "dark"],
            },
            [
              _c("div", { staticClass: "main_big_box" }, [
                _c(
                  "div",
                  { staticClass: "main" },
                  [
                    _c("div", { staticClass: "searchbox" }, [
                      _c("div", { staticClass: "search_input" }, [
                        _c("div", {
                          staticClass: "search_icon el-icon-search",
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.keywords,
                              expression: "keywords",
                            },
                          ],
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("orders.placeholder"),
                          },
                          domProps: { value: _vm.keywords },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.keywords = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "topbox" }, [
                        _c(
                          "div",
                          {
                            staticClass: "comBtn btn-bg0",
                            on: {
                              click: function ($event) {
                                return _vm.getOrderList()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("orders.search")) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "comBtn btn-bg0",
                            on: { click: _vm.toggleMore },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("orders.filter")) + " ")]
                        ),
                        _vm.paymentList.IsAdmin == true
                          ? _c(
                              "div",
                              {
                                staticClass: "comBtn btn-bg0",
                                on: { click: _vm.UploadOrder },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("orders.UploadOrder")) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.paymentList.IsAdmin == true
                          ? _c(
                              "div",
                              {
                                staticClass: "comBtn btn-bg0",
                                on: { click: _vm.AddOrder },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("orders.AddOrder")) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.paymentList.IsAdmin == true
                          ? _c(
                              "div",
                              { staticClass: "Synchronize comBtn btn-bg7" },
                              [
                                _c("a", { attrs: { href: "/synchronize" } }, [
                                  _c("span", { on: { click: _vm.getOrders } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("orders.Synchronize"))
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "add_shopify comBtn btn-bg2" },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://shopify.pxf.io/aONbVN",
                                  target: "_blank",
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        "If you don't have a Shopify store, click this button to create one quickly.",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "comBtn linkStore btn-bg2",
                                      },
                                      [_vm._v(" Add Shopify ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          visible: _vm.isMore,
                          width: "600px",
                          "custom-class": "mydialog",
                          center: true,
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.isMore = $event
                          },
                        },
                      },
                      [
                        _c(
                          "el-form",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("orders.dialog_country"),
                                  "label-width": "120px",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "myselect",
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: _vm.$t(""),
                                    },
                                    model: {
                                      value: _vm.selectCountry,
                                      callback: function ($$v) {
                                        _vm.selectCountry = $$v
                                      },
                                      expression: "selectCountry",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      _vm._l(_vm.countrys, function (item) {
                                        return _c("el-option", {
                                          key: item.index,
                                          attrs: {
                                            label: item.EnglishName,
                                            value: item.DoubleCharacterCode,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("orders.dialog_store"),
                                  "label-width": "120px",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "myselect",
                                    attrs: {
                                      clearable: "",
                                      placeholder: _vm.$t(""),
                                    },
                                    model: {
                                      value: _vm.selectStore,
                                      callback: function ($$v) {
                                        _vm.selectStore = $$v
                                      },
                                      expression: "selectStore",
                                    },
                                  },
                                  _vm._l(_vm.stores, function (item) {
                                    return _c("el-option", {
                                      key: item.index,
                                      attrs: {
                                        label: item.StoreName,
                                        value: item.StoreId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("orders.dialog_time"),
                                  "label-width": "120px",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticClass: "myselect",
                                  attrs: {
                                    type: "datetimerange",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    "range-separator": _vm.$t("orders.to"),
                                    "start-placeholder": _vm.$t(""),
                                    "end-placeholder": _vm.$t(""),
                                  },
                                  on: { change: _vm.timeChange },
                                  model: {
                                    value: _vm.selectTime,
                                    callback: function ($$v) {
                                      _vm.selectTime = $$v
                                    },
                                    expression: "selectTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("orders.ProductName"),
                                  "label-width": "120px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "Contact_input" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "orders.FilterProductName"
                                        ),
                                      },
                                      model: {
                                        value: _vm.productName,
                                        callback: function ($$v) {
                                          _vm.productName = $$v
                                        },
                                        expression: "productName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("orders.Contact"),
                                  "label-width": "120px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "Contact_input" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "orders.FilterContact"
                                        ),
                                      },
                                      model: {
                                        value: _vm.contact,
                                        callback: function ($$v) {
                                          _vm.contact = $$v
                                        },
                                        expression: "contact",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("orders.SalesRecord"),
                                  "label-width": "120px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "Contact_input" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "orders.FilterSalesRecord"
                                        ),
                                      },
                                      model: {
                                        value: _vm.salesRecord,
                                        callback: function ($$v) {
                                          _vm.salesRecord = $$v
                                        },
                                        expression: "salesRecord",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("orders.statusFilter"),
                                  "label-width": "120px",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "myselect",
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.PlatformStatus,
                                      callback: function ($$v) {
                                        _vm.PlatformStatus = $$v
                                      },
                                      expression: "PlatformStatus",
                                    },
                                  },
                                  _vm._l(_vm.optionsType, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "comBtn btn-bg0 min-wid200",
                                on: { click: _vm.moreSearch },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("orders.Confirm")) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "list" }, [
                      _c("div", { staticClass: "typebox" }, [
                        _c(
                          "div",
                          { staticClass: "typebox-tabs" },
                          _vm._l(_vm.types, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "type",
                                class:
                                  _vm.orderType == index + 1 ? "select" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.selectType(index + 1)
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(`${_vm.$t(item)}`)),
                                _c("span"),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm.BindStoreList.IsStoreComplete &&
                        _vm.BindStoreList.IsOfflineStoreComplete
                          ? _c("div", { staticClass: "typebox-btn-group" }, [
                              _vm.paymentList.IsAdmin == true
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "all comBtn border-col5 col-col5",
                                      on: { click: _vm.AddStoreBind },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("orders.AddStore")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.paymentList.IsAdmin == true
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "all comBtn border-col5 col-col5",
                                      on: { click: _vm.ExportExcelBtn },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("orders.ImportOrder")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.paymentList.IsAdmin == true
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "all comBtn border-col5 col-col5",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.payAllUpgrade.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("orders.fulfill")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _c("div", { staticClass: "typebox-btn-group" }, [
                              _vm.paymentList.IsAdmin == true
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "all comBtn border-col5 col-col5",
                                      on: { click: _vm.AddStoreBind },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("orders.AddStore")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.paymentList.IsAdmin == true
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "all comBtn border-col5 col-col5",
                                      on: { click: _vm.ExportExcelBtn },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("orders.ImportOrder")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.paymentList.IsAdmin == true
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "all comBtn border-col5 col-col5",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.payAllUpgrade.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("orders.fulfill")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                      ]),
                      _vm.Fulfilled
                        ? _c(
                            "div",
                            { staticClass: "speed-progress" },
                            [
                              _c("div", { staticClass: "progress-fulfilled" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("orders.OrdersFulfilled"))
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.MembershipInterests.UsedTimes)
                                  ),
                                ]),
                                _c("span", [_vm._v("/")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.MembershipInterests.TotalTimes)
                                  ),
                                ]),
                              ]),
                              _c("el-progress", {
                                staticStyle: {
                                  width: "170px",
                                  "margin-top": "8px",
                                },
                                attrs: {
                                  percentage: _vm.pmgressbar,
                                  "show-text": false,
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "MonthlyRed",
                                  on: {
                                    click: function ($event) {
                                      return _vm.MonthlyRed()
                                    },
                                  },
                                },
                                [
                                  _vm.paymentList.GradeId === 1
                                    ? _c("span", [
                                        _c("img", {
                                          attrs: {
                                            src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591998939_MemberJICHUSlice.png",
                                            alt: "",
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                  _vm.paymentList.GradeId === 2
                                    ? _c("span", [
                                        _c("img", {
                                          attrs: {
                                            src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591999005_MemberVIPSlice16.png",
                                            alt: "",
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                  _vm.paymentList.GradeId === 3
                                    ? _c("span", [
                                        _c("img", {
                                          attrs: {
                                            src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591998972_MemberSVIPSlice16.png",
                                            alt: "",
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "ul",
                      { staticClass: "main-list" },
                      [
                        _c("li", { staticClass: "title" }, [
                          _c("div", { staticClass: "content" }, [
                            _c("div", { staticClass: "title_li" }, [
                              _vm._v(_vm._s(_vm.$t("orders.id"))),
                            ]),
                            _c("div", { staticClass: "title_li" }, [
                              _vm._v(_vm._s(_vm.$t("orders.time"))),
                            ]),
                            _c("div", { staticClass: "title_li" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("orders.ProductInformations")) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "title_li" }, [
                              _vm._v(_vm._s(_vm.$t("orders.user"))),
                            ]),
                            _c("div", { staticClass: "title_li" }, [
                              _vm._v(_vm._s(_vm.$t("orders.payment"))),
                            ]),
                            _c("div", { staticClass: "title_li" }, [
                              _vm._v(_vm._s(_vm.$t("orders.Tax"))),
                            ]),
                            _c("div", { staticClass: "title_li" }, [
                              _vm._v(_vm._s(_vm.$t("orders.pay"))),
                            ]),
                            _c("div", { staticClass: "title_li" }, [
                              _vm._v(_vm._s(_vm.$t("orders.Tracking"))),
                            ]),
                            _c("div", { staticClass: "title_li" }, [
                              _vm._v(_vm._s(_vm.$t("orders.status"))),
                            ]),
                          ]),
                        ]),
                        _vm._l(_vm.orderlist, function (order, index) {
                          return [
                            _c(
                              "li",
                              {
                                key: order.SalesRecord,
                                staticClass: "common",
                                class: _vm.getOrder === index ? "canceled" : "",
                              },
                              [
                                _c("div", { staticClass: "contentMessage" }, [
                                  _c("div", { staticClass: "content_li" }, [
                                    _vm._v(_vm._s(order.SalesRecord)),
                                  ]),
                                  _c("div", { staticClass: "content_li" }, [
                                    _vm._v(_vm._s(order.OrderTime)),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "content_li" },
                                    [
                                      order.OrderDetailVos
                                        ? [
                                            _vm._l(
                                              order.OrderDetailVos,
                                              function (
                                                productList,
                                                itemIndex
                                              ) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      key: productList.id,
                                                      staticClass:
                                                        "productListContent",
                                                      class: [
                                                        productList.IsExclude
                                                          ? "cancel"
                                                          : "",
                                                      ],
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "productListContentBox",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "productListContentImg",
                                                            },
                                                            [
                                                              _c(
                                                                "el-image",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "60px",
                                                                    height:
                                                                      "60px",
                                                                  },
                                                                  attrs: {
                                                                    src: productList.ProductPictureUrl,
                                                                    alt: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "image-slot",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                          height:
                                                                            "100%",
                                                                          display:
                                                                            "flex",
                                                                          "justify-content":
                                                                            "center",
                                                                          "align-items":
                                                                            "center",
                                                                          "background-color":
                                                                            "#f5f7fa",
                                                                        },
                                                                      attrs: {
                                                                        slot: "error",
                                                                      },
                                                                      slot: "error",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-picture-outline",
                                                                      }),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c("div", [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "productListProductName",
                                                                on: {
                                                                  mouseenter:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.enterProduct(
                                                                        productList
                                                                      )
                                                                    },
                                                                  mouseleave:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.leaveProduct(
                                                                        productList
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      productList.ProductName
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      productList.Spec
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      productList.ShowProductName,
                                                                    expression:
                                                                      "productList.ShowProductName",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "ShowProductListProductName",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      productList.ProductName
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      productList.Spec
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "productListProductNumber",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cheapPrice",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          productList.Num
                                                                        ) +
                                                                        " * $" +
                                                                        _vm._s(
                                                                          productList.QuotedPrice
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            productList.PodAttr
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "podTag",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "pod"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            productList.ProductSaleType ==
                                                            2
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "NoNeedToShip",
                                                                  },
                                                                  [
                                                                    _c("p", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "orders.NoNeedToShip"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c("div", [
                                                        productList.IsNotProvide
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#bc0c34",
                                                                },
                                                              },
                                                              [
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "orders.failedFulfillment"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        productList.IsNotProvide
                                                          ? _c("div", {
                                                              staticStyle: {
                                                                color: "BC0C34",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]),
                                                      order.OrderStatus !==
                                                      "Paid"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "text-align":
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _vm.paymentList
                                                                .IsAdmin == true
                                                                ? _c("span", [
                                                                    productList.IsExclude
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                border:
                                                                                  "0",
                                                                                background:
                                                                                  "none",
                                                                                color:
                                                                                  "#bc0c34",
                                                                                "margin-right":
                                                                                  "20px",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.cancelItem(
                                                                                    index,
                                                                                    itemIndex
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "orders.RecoverItem"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "cancel-btn",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-button",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    border:
                                                                                      "0",
                                                                                    background:
                                                                                      "none",
                                                                                    color:
                                                                                      "#bc0c34",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    slot: "reference",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.cancelItem(
                                                                                        index,
                                                                                        itemIndex
                                                                                      )
                                                                                    },
                                                                                },
                                                                                slot: "reference",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "orders.CancelItem"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              }
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "content_li",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.showUser(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", [_vm._v(_vm._s(order.Contact))]),
                                      _c("span", {
                                        class: [
                                          order.isInfo ? "info_down" : "",
                                        ],
                                      }),
                                    ]
                                  ),
                                  _c("div", { staticClass: "content_li" }, [
                                    _vm._v(
                                      " " + _vm._s(order.CustomerPayment) + " "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "content_li" }, [
                                    _vm._v(
                                      "$" +
                                        _vm._s(order.SalesTaxAmount.toFixed(2))
                                    ),
                                  ]),
                                  _c("div", { staticClass: "content_li" }, [
                                    order.OrderStatus === "Unpaid" &&
                                    order.IsQuotedPrice
                                      ? _c("div", [
                                          order.DiscountAmount !== 0
                                            ? _c(
                                                "div",
                                                { staticClass: "copper_coin" },
                                                [
                                                  _vm._v(
                                                    " $" +
                                                      _vm._s(
                                                        order.QuotedPrice
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("div", [
                                            _vm._v(
                                              " $" +
                                                _vm._s(
                                                  Number(
                                                    order.QuotedPrice -
                                                      order.DiscountAmount
                                                  ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ])
                                      : _c("div", [
                                          _vm._v(
                                            "$" + _vm._s(order.QuotedPrice)
                                          ),
                                        ]),
                                  ]),
                                  _c("div", { staticClass: "content_li" }, [
                                    _c(
                                      "div",
                                      { staticClass: "OrderTrackingNumber" },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              order.TrackingNumber || "    一"
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "content_li" }, [
                                    _vm.paymentList.IsAdmin == true
                                      ? _c(
                                          "div",
                                          { staticClass: "order_Btn" },
                                          [
                                            order.OrderStatus === "Unpaid" &&
                                            order.IsQuotedPrice
                                              ? _c("div", [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            !_vm.CenterMessage
                                                              .AutoPaymentMode,
                                                          expression:
                                                            "!CenterMessage.AutoPaymentMode",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "comBtn btn-bg7 min-wid100",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.goToUpgrade(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "orders.pay1"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : !order.IsQuotedPrice &&
                                                order.OrderStatus === "Unpaid"
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "plain",
                                                    attrs: {
                                                      disabled: "disabled",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "orders.Quoting"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : order.OrderStatus === "Paid"
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "comBtn btn-bg0 min-wid100",
                                                    attrs: {
                                                      disabled: "disabled",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("orders.Paid")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : order.OrderStatus === "Cancel"
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "CanceledBtn",
                                                    attrs: {
                                                      disabled: "disabled",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "orders.Canceled"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: order.isInfo,
                                    expression: "order.isInfo",
                                  },
                                ],
                                staticClass: "infobox",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.stopClick.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-form",
                                  {
                                    ref: `form${index}`,
                                    refInFor: true,
                                    attrs: {
                                      model: order.user,
                                      rules: _vm.rules,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "Form_big_box" }, [
                                      _c(
                                        "div",
                                        { staticClass: "LeftForm" },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "Contact" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "contact_name_input",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "contact_name_words",
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "orders.contact_name"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "REQUIRED" },
                                                    [_vm._v("*")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "order_user_Contact",
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            order.user.Contact,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              order.user,
                                                              "Contact",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "order.user.Contact",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { prop: "CountryNameEn" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "contact_name_input",
                                                },
                                                [
                                                  _c("div", {}, [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("orders.Country")
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "PleaseSelect_Country",
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            disabled: "",
                                                            placeholder: _vm.$t(
                                                              "orders.PleaseSelect"
                                                            ),
                                                          },
                                                          model: {
                                                            value:
                                                              order.user
                                                                .CountryCode,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                order.user,
                                                                "CountryCode",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "order.user.CountryCode",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.countrys,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.index,
                                                                attrs: {
                                                                  label:
                                                                    item.EnglishName,
                                                                  value:
                                                                    item.DoubleCharacterCode,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "Province" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "contact_Province_input",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "contact_Province_words",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "orders.Province"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "REQUIRED" },
                                                    [_vm._v("*")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "order_user_Province",
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            order.user.Province,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              order.user,
                                                              "Province",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "order.user.Province",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "ZipCode" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "contact_ZipCode_input",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "orders_Post_code",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "orders.Post_code"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "order_user_ZipCode",
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            order.user.ZipCode,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              order.user,
                                                              "ZipCode",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "order.user.ZipCode",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "TrackingNo" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "orders_TrackingNo_words",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "orders.TrackingNumber"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "order_TrackingNumber",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(order.TrackingNumber)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "RightForm" },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "Address1" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "contact_Address_input",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "orders_Address_words",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "orders.Address"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "REQUIRED" },
                                                    [_vm._v("*")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "order_user_Address",
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            order.user.Address1,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              order.user,
                                                              "Address1",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "order.user.Address1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "Address2" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "contact_Address2_input",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "orders_Address2_words",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "orders.Address2"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "order_user_Address2",
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            order.user.Address2,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              order.user,
                                                              "Address2",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "order.user.Address2",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "TelePhone" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "contact_TelePhone_input",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "orders_TelePhone_words",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("orders.Tel")
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "REQUIRED" },
                                                    [_vm._v("*")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "order_user_TelePhone",
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            order.user
                                                              .TelePhone,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              order.user,
                                                              "TelePhone",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "order.user.TelePhone",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "City" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "contact_City_input",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "REQUIRED" },
                                                    [_vm._v("*")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "orders_City_words",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "orders.City"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "order_user_City",
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            order.user.City,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              order.user,
                                                              "City",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "order.user.City",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        }),
                        _vm.BindStoreList.IsStoreComplete == false &&
                        _vm.BindStoreList.IsOfflineStoreComplete == false
                          ? _c(
                              "el-empty",
                              {
                                attrs: {
                                  image: require("../../assets/imgs/NavGuide/FrameStore.png"),
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "AddStoreJiaHao comBtn btn-bg0",
                                    on: { click: _vm.AddStoreBtn },
                                  },
                                  [
                                    _c("p", [
                                      _c("img", {
                                        attrs: {
                                          src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715592137077_AddStoreJiaHao.png",
                                          alt: "",
                                        },
                                      }),
                                    ]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.$t("orders.AddStore"))),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "pagebox" },
                          [
                            _c(
                              "el-pagination",
                              {
                                attrs: {
                                  layout: "prev, pager, next,slot",
                                  "page-size": _vm.pageSize,
                                  "current-page": _vm.quotedList[0].page,
                                  "page-sizes": [],
                                  total: _vm.quotedList[0].total,
                                },
                                on: {
                                  "current-change": _vm.pageChange,
                                  "size-change": _vm.handleSizeChange,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "pageSizeInput" },
                                  [
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          on: { change: _vm.selectChanged },
                                          model: {
                                            value: _vm.pageSize,
                                            callback: function ($$v) {
                                              _vm.pageSize = $$v
                                            },
                                            expression: "pageSize",
                                          },
                                        },
                                        _vm._l(
                                          _vm.optionsSize,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "page_sign",
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.getPageList(0)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("orders.get"))),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.quotedList[0].jumpPage,
                                      expression: "quotedList[0].jumpPage",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.quotedList[0].jumpPage,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.quotedList[0],
                                        "jumpPage",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm.isPay
            ? _c("div", { staticClass: "paybox" }, [
                _c("div", { staticClass: "pay_content" }, [
                  _c("p", { staticClass: "pay_title" }, [
                    _vm._v(_vm._s(_vm.$t(""))),
                    _c("span", { on: { click: _vm.cancelPay } }),
                  ]),
                  _c("div", { staticClass: "pay_items" }, [
                    _c("div", { staticClass: "pay_item" }, [
                      _c("div", { staticClass: "pay_left" }, [
                        _vm._v(_vm._s(_vm.$t("orders.account"))),
                      ]),
                      _c("div", { staticClass: "pay_right" }, [
                        _vm._v(
                          _vm._s(_vm.$t("orders.sum")) +
                            _vm._s(_vm.account.BalanceWithCredits)
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "pay_item" }, [
                      _c("div", { staticClass: "pay_left" }, [
                        _vm._v(_vm._s(_vm.$t("orders.UcreditBalance"))),
                      ]),
                      _c("div", { staticClass: "pay_right" }, [
                        _c("span", [
                          _vm._v("U" + _vm._s(_vm.account.CreditCoin)),
                        ]),
                        _vm.payOrder.WanaPayCreditCoin !== 0
                          ? _c(
                              "span",
                              { staticStyle: { "margin-left": "5px" } },
                              [
                                _vm._v(
                                  "(-U" +
                                    _vm._s(_vm.payOrder.WanaPayCreditCoin) +
                                    ")"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "pay_item" }, [
                      _c("div", { staticClass: "pay_left" }, [
                        _vm._v(_vm._s(_vm.$t("orders.amount"))),
                      ]),
                      _c("div", { staticClass: "pay_right" }, [
                        _vm._v(
                          _vm._s(_vm.$t("orders.sum")) +
                            _vm._s(_vm.payOrder.OrdersAmount)
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "pay_item" }, [
                      _c("div", { staticClass: "pay_left" }, [
                        _vm._v(_vm._s(_vm.$t("orders.Tax")) + ":"),
                      ]),
                      _c("div", { staticClass: "pay_right" }, [
                        _vm._v(
                          _vm._s(_vm.$t("orders.sum")) +
                            _vm._s(_vm.payOrder.TaxAmount)
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "pay_item" }, [
                      _c(
                        "div",
                        {
                          staticClass: "pay_left",
                          staticStyle: { "font-weight": "600" },
                        },
                        [_vm._v(_vm._s(_vm.$t("orders.GrandTotal")))]
                      ),
                      _c("div", { staticClass: "pay_right" }, [
                        _c("span", { staticStyle: { "font-weight": "600" } }, [
                          _vm._v(" " + _vm._s(_vm.$t("orders.sum")) + " "),
                          _c("span", { staticStyle: { "font-size": "22px" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.payOrder.WanaPayBalance) + " "
                            ),
                          ]),
                          _c("span", [
                            _c("span", { staticClass: "col-col2" }, [
                              _vm.payOrder.TotalDeductionAmount > 0
                                ? _c("span", [
                                    _vm._v(
                                      " (Saved $ " +
                                        _vm._s(
                                          _vm.payOrder.TotalDeductionAmount
                                        ) +
                                        ") "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "Member_exclusive" }, [
                      _c("p", { staticClass: "col-col5" }, [
                        _c("span", { staticClass: "col-col7" }, [_vm._v("*")]),
                        _vm._v(_vm._s(_vm.$t("orders.Included"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "pay_footer" }, [
                      _c(
                        "div",
                        {
                          staticClass: "comBtn border-col0 btn col-col0",
                          on: { click: _vm.rechargeFun },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("orders.recharge")) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.CenterMessage.AutoPaymentMode,
                              expression: "!CenterMessage.AutoPaymentMode",
                            },
                          ],
                          staticClass: "comBtn btn-bg0 btn",
                          on: {
                            click: function ($event) {
                              return _vm.goPay()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("orders.pay1")) + " ")]
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.memberPopupBox
            ? _c("div", { staticClass: "member_box" }, [
                _c("div", { staticClass: "member_set" }, [
                  _c("div", { staticClass: "member_content" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "member_down" }, [
                      _c("p", [_vm._v(_vm._s(_vm.$t("orders.YouHave")))]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("orders.MoreOrders")))]),
                    ]),
                    _c("div", { staticClass: "Bindstore" }, [
                      _c("p", [
                        _c(
                          "span",
                          {
                            staticStyle: { color: "red", "font-size": "20px" },
                          },
                          [_vm._v("*")]
                        ),
                        _vm._v(
                          " " + _vm._s(_vm.$t("orders.BindMembership")) + " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "member_btn" },
                    [
                      _c("el-button", { on: { click: _vm.ComparePriceBtn } }, [
                        _vm._v(_vm._s(_vm.$t("orders.ViewPlan"))),
                      ]),
                      _c("el-button", { on: { click: _vm.AddStoreBtn } }, [
                        _vm._v(_vm._s(_vm.$t("orders.AddStore"))),
                      ]),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.showUpgrade
            ? _c("div", [
                _c("div", { staticClass: "UploadOrderBox" }, [
                  _c(
                    "div",
                    { staticClass: "UploadOrderContent upgradeDialog" },
                    [
                      _c("div", { staticClass: "btns" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btn",
                            on: { click: _vm.cancelUpgrade },
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "btn",
                            on: {
                              click: function ($event) {
                                return _vm.$router.push("/Upgrade")
                              },
                            },
                          },
                          [_vm._v("Upgrade")]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.UploadOrderBox
            ? _c("div", { staticClass: "UploadOrderBox" }, [
                _c("div", { staticClass: "UploadOrderContent" }, [
                  _c("div", { staticClass: "uploadOrderTitle" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("orders.UploadOrders")))]),
                    _c("p", { on: { click: _vm.closeUploadBtn } }, [
                      _c("i", { staticClass: "el-icon-close" }),
                    ]),
                  ]),
                  _c("div", { staticClass: "Upload_order_template" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/static/USAdropOrderImportTemplate.xlsx",
                          download: "",
                        },
                      },
                      [
                        _vm._m(1),
                        _c("P", [_vm._v(_vm._s(_vm.$t("orders.UploadFile")))]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "UploadFileContent" }, [
                    _c(
                      "div",
                      { staticClass: "UploadFileBtn" },
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "upload",
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.action,
                              "on-preview": _vm.handlePreview,
                              "on-remove": _vm.handleRemove,
                              "auto-upload": false,
                              headers: _vm.headersaa,
                              "on-success": _vm.handleSuccess,
                              name: "File",
                              limit: 1,
                            },
                          },
                          [
                            _c(
                              "p",
                              {
                                on: {
                                  mouseenter: _vm.onMouseOver,
                                  mouseleave: _vm.onMouseOut,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "comBtn btn-bg5 col-col8",
                                    attrs: { size: "small" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("orders.ChooseFile")))]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { size: "small" },
                            on: { click: _vm.submitUpload },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("orders.Submit")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "TextareaContent" }, [
                    _c(
                      "p",
                      {
                        on: {
                          clear: function ($event) {
                            return _vm.getGoodsList()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.UploadMessage))]
                    ),
                  ]),
                  _vm.PleaseDownload
                    ? _c("div", { staticClass: "Please_download" }, [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("orders.PleaseTemplate")) + " "
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("orders.corresponMandatory")) +
                              " "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "BackTop",
              attrs: { id: "toTop" },
              on: {
                click: function ($event) {
                  return _vm.toTop(_vm.step)
                },
              },
            },
            [_vm._m(2)]
          ),
          _c("MemberBindBox", { ref: "child" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "member_top" }, [
      _c("img", {
        attrs: {
          src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715592188971_HuoJianAnYe.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: {
          src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715592266930_GroupDload.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: {
          src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715592306320_BackTopUp.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }