<template>
  <div class="content-left">
    <div class="login-info">
      <img
        src="@/assets/imgs/NewLogo/left_img_ads_logo.svg"
        alt=""
        class="logo1"
      />
      <div class="welcome-title">
        <div class="ft-56 fw-800 color2">USAdrop</div>
        <div class="ft-56 fw-800 color1 mb-24">Payment Solution</div>
      </div>
      <div class="bottomContent">
        <div class="items1">
          <div class="payment_icon">
            <img src="https://file.securepago.com/ud/1737363984482_payment_icon.svg" alt="">
          </div>
          <div class="tip-text"></div>
          <div class="support_methods">
            Support for local payment methods in
            <span>LATAM</span>
          </div>
        </div>
        <div class="items2">
          <div class="payment_icon">
            <img src="https://file.securepago.com/ud/1737363985391_store_icon.svg" alt="">
          </div>
          <div class="tip-text"></div>
          <div class="support_methods">
            <span>500+ million</span>
            potential online buyers
          </div>
        </div>
        <div class="items2">
          <div class="payment_icon">
            <img src="https://file.securepago.com/ud/1737363985830_trend_icon.png" alt="">
          </div>
          <div class="tip-text"></div>
          <div class="support_methods">
            Estimated
            <span>$8.1 trillion</span>
            in online sales by 2026
          </div>
        </div>
      </div>
      <div class="opportunity">
        Don't miss the opportunity to tap into LATAM's fastest-growing
      </div>
      <div class="worldwide">
        e-commerce market worldwide and become a millionaire!
      </div>
    </div>
  </div>
</template>
<script>
import { getFrom } from '@/utils/tools'
export default {
  data: () => {
    return {
      logoImg: {
        'app.usadrop.com': require('@/assets/imgs/NewLogo/LogoOne.svg'),
        'vn.usadrop.com': require('@/assets/imgs/NewLogo/VNLogoSlice.png')
      },
      formLink: getFrom()
    }
  }
}
</script>
<style scoped lang="scss">
$color1: #bc0c34;
$color2: #15104b;
.login-info {
  // width: 60%;
  margin-right: 80px;
  text-align: left;
  .logo1 {
    width: 225px;
    height: 189px;
    margin-bottom: 40px;
  }
  .logo2 {
    width: 428px;
    height: 42px;
    margin: 32px 0 32px 0;
  }

  .bottomContent {
    color: $color2;
    width: 876px;
    border-radius: 16px;
    text-align: center;
    .items1 {
      width: 690px;
      height: 55px;
      display: flex;
      background: #E0E3F2;
      border-radius: 16px;
    }
    .items2 {
      width: 690px;
      height: 55px;
      display: flex;
      background: #E0E3F2;
      border-radius: 16px;
      margin-top: 30px;
    }
    .payment_icon {
      margin-left: 30px;
      margin-top: 10px;
    }
    .tip-text {
      width: 2px;
      height: 30px;
      background: #231D65;
      margin-left: 10px;
      margin-top: 11px
    };
    .support_methods {
      font-size: 24px;
      font-weight: 800;
      color: #15104B;
      margin-top: 11px;
      margin-left: 20px;
      span {
        color: #C00A31;
      }
    }
  }
  .opportunity {
    font-size: 20px;
    font-weight: 800;
    color: #15104B;
    margin-top: 35px
  }
  .worldwide {
    font-size: 20px;
    font-weight: 800;
    color: #15104B;
    margin-top: 15px
  }
}
.login-content {
  width: 503px;
  height: 708px;
  margin-top: -6%;
  background: #fff;
  border-radius: 30px;
  padding: 20px 40px;
  position: relative;
  .logo2 {
    width: 90px;
    height: 64px;
    position: absolute;
    left: 40px;
    margin-bottom: 30px;
  }
  .login-head {
    font-size: 24px;
    color: #000000;
    font-weight: 500;
    margin-top: 104px;
    margin-bottom: 40px;
    text-align: center;
  }
  .forget {
    width: 100%;
    text-align: right;
    position: absolute;
    top: 44px;
  }
  .option {
    width: 420px;
    height: 100px;
    margin: 0 auto 10px;
    padding-left: 3px;
    ::v-deep .el-input__inner {
      border-radius: 50px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      width: 100%;
      height: 42px;
      border: 1px solid #cccccc;
    }
  }
  .login-bottom {
    margin-top: 80px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .el-button {
      width: 226px;
      height: 44px;
      background: $color2;
      font-size: 16px;
      margin-bottom: 8px;
      color: #ffffff;
      border-radius: 30px;
    }
    .small {
      font-size: 14px;
      color: #999999;
    }
  }

  .demo-ruleForm {
    ::v-deep .el-form-item {
      margin-bottom: 0 !important;
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}
.btnContent {
  display: flex;
  width: 620px;
  flex-wrap: wrap;
  justify-content: space-between;
  .btnInfo {
    background: #e0e3f2;
    box-shadow: 2px 3px 4px 0px #231d6540;
    width: 260px;
    border-radius: 16px;
    margin-bottom: 40px;
    font-size: 16px;
    color: $color2;
    display: flex;
    align-items: center;
    padding: 8px;
    img {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
    .tip-text {
      padding-left: 8px;
      color: $color2;
      font-weight: 600;
      border-left: 1.5px solid $color2;
    }
  }
}
</style>
